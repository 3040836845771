import { useContext } from "react"
import {
  WebhookFormContext,
  WebhookFormData,
} from "@/context/WebhookFormProvider"

export function useWebhookForm() {
  const { data, setData } = useContext(WebhookFormContext)

  return {
    data,
    updateData: (data: Partial<WebhookFormData>) => {
      setData((prev) => ({ ...prev, ...data }))
    },
  }
}
