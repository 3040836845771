import { PropsWithChildren } from "react"
import { Alert, AlertTitle, Stack } from "@mui/material"
import * as Sentry from "@sentry/react"

export function GlobalErrorBoundary({ children }: PropsWithChildren<{}>) {
  return (
    <Sentry.ErrorBoundary fallback={<FallbackComponent />}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

function FallbackComponent() {
  return (
    <Stack justifyContent="center" alignItems="center" height="100vh">
      <Alert variant="filled" severity="error" sx={{ width: 300 }}>
        <AlertTitle>An error has occurred</AlertTitle>
        Clear site data and reload the page
      </Alert>
    </Stack>
  )
}
