export type Scope = {
  name: string
  permissions: {
    type: PermissionType
    description: string
  }[]
}

export type PermissionType = "Read" | "Write"

export const TokenScopesConfig: Scope[] = [
  {
    name: "Messaging",
    permissions: [
      {
        type: "Read",
        description: "Access chats, messages, users",
      },
      {
        type: "Write",
        description: "Send messages",
      },
    ],
  },
  {
    name: "Accounts",
    permissions: [
      {
        type: "Read",
        description: "Access accounts list and status",
      },
      {
        type: "Write",
        description: "Connect new accounts",
      },
    ],
  },
  {
    name: "Webhooks",
    permissions: [
      {
        type: "Read",
        description: "Access webhooks list and status",
      },
      {
        type: "Write",
        description: "Create new webhooks",
      },
    ],
  },
]

export const TokenExpirationDates = [
  {
    label: "7 days",
    value: 7,
  },
  {
    label: "30 days",
    value: 30,
  },
  {
    label: "60 days",
    value: 60,
  },
  {
    label: "90 days",
    value: 90,
  },
  {
    label: "Custom date...",
    value: 0,
  },
]
