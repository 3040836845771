import React from "react"
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material"
import dayjs from "dayjs"
import { BookOpen, ExternalLink, HelpCircle, LogOut, Radio } from "lucide-react"
import { Outlet, useMatch, useNavigate } from "react-router"

import { AppMenuConfig, MENU_WIDTH, OrgMenuConfig } from "@/config/menu"
import { showCrisp } from "@/lib/crisp"
import { useAuth } from "@/hooks/useAuth"
import { useRefreshSession } from "@/hooks/useRefreshSession"
import { useSignOut } from "@/hooks/useSignOut"
import { useSubscriptionStatus } from "@/hooks/useSubscriptionStatus"
import { Copyable } from "@/components/Copyable"

import logo from "/logo.svg"

export default function Root() {
  useRefreshSession()

  return (
    <Box
      pl={`${MENU_WIDTH + 30}px`}
      pr={"30px"}
      minHeight="100vh"
      minWidth={900}
    >
      <Menu />
      <Container component="main" sx={{ py: 10 }} disableGutters>
        <Outlet />
      </Container>
    </Box>
  )
}

function Menu() {
  return (
    <Stack
      component="nav"
      sx={{
        width: `${MENU_WIDTH}px`,
        bgcolor: "background.paper",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        borderRight: 1,
        borderColor: "divider",
      }}
    >
      <Stack p={4} direction="row" spacing={2} alignItems="center">
        <img src={logo} height={25} />
      </Stack>
      <Divider />
      <InstanceInfos />
      <Divider />
      <Box flex={1} sx={{ overflowY: "scroll" }}>
        <Box px={2}>
          <List dense>
            {AppMenuConfig.map(({ Icon, href, title }, i) => (
              <MenuItem
                key={i}
                Icon={<Icon size={16} />}
                title={title}
                href={href}
              />
            ))}
            {OrgMenuConfig.map(({ Icon, href, title }, i) => (
              <MenuItem
                key={i}
                Icon={<Icon size={16} />}
                title={title}
                href={href}
              />
            ))}
          </List>
        </Box>
      </Box>

      <List dense sx={{ px: 2 }}>
        <ListItemButton onClick={() => showCrisp()}>
          <ListItemIcon>
            <HelpCircle size={16} />
          </ListItemIcon>
          <ListItemText
            primary="Contact & Support"
            primaryTypographyProps={{ noWrap: true }}
          ></ListItemText>
        </ListItemButton>
        <MenuItem
          Icon={<BookOpen size={16} />}
          title="Documentation"
          href="https://developer.unipile.com/docs"
          external
        />
        <LogoutButton />
      </List>
    </Stack>
  )
}

function InstanceInfos() {
  const { canUseApi, dsn, isFreeTrialActive, isSubscriptionActive, auth } =
    useSubscriptionStatus()

  if (!dsn) return <p>Error to display DSN</p>

  return (
    <List dense sx={{ px: 2 }}>
      {canUseApi && (
        <Copyable value={dsn}>
          <ListItemButton>
            <ListItemIcon>
              <Radio size={16} />
            </ListItemIcon>
            <ListItemText
              primary="Your DSN"
              secondary={dsn}
              primaryTypographyProps={{ noWrap: true }}
            ></ListItemText>
          </ListItemButton>
        </Copyable>
      )}

      {!isSubscriptionActive && (
        <ListItem>
          <ListItemText
            sx={{
              textAlign: "center",
              bgcolor: isFreeTrialActive ? "info.light" : "warning.light",
              py: 1,
              borderRadius: 1,
            }}
            primaryTypographyProps={{
              color: "white",
              fontWeight: "bold",
              fontSize: 12,
            }}
            secondaryTypographyProps={{ color: "white", fontSize: 12 }}
            primary="Free trial"
            secondary={
              isFreeTrialActive && auth
                ? `Expires in ${dayjs(auth.instance.expire_at).diff(
                    dayjs(),
                    "d"
                  )} day(s)`
                : "Expired. Subscribe now."
            }
          ></ListItemText>
        </ListItem>
      )}
    </List>
  )
}

function LogoutButton() {
  const signOut = useSignOut()
  return (
    <ListItemButton onClick={signOut}>
      <ListItemIcon>
        <LogOut size={16} />
      </ListItemIcon>
      <ListItemText
        primary="Logout"
        primaryTypographyProps={{ noWrap: true }}
      ></ListItemText>
    </ListItemButton>
  )
}

function MenuItem({
  href,
  Icon,
  title,
  external,
}: {
  href: string
  Icon: React.ReactNode
  title: string
  external?: boolean
}) {
  const match = useMatch({ path: href, end: false })
  const navigate = useNavigate()

  return (
    <ListItemButton
      selected={Boolean(match)}
      onClick={(e) => (external ? window.open(href, "_blank") : navigate(href))}
    >
      <ListItemIcon>{Icon}</ListItemIcon>
      <ListItemText
        primary={title}
        primaryTypographyProps={{ noWrap: true }}
      ></ListItemText>
      {external && <ExternalLink size={12} />}
    </ListItemButton>
  )
}
