import { ChatboxColors, Crisp } from "crisp-sdk-web"

Crisp.configure("4f1e445d-dda6-4f76-8027-ded4b861290d", { autoload: false })

export function loadCrisp(email: string) {
  Crisp.setColorTheme(ChatboxColors.Black)
  Crisp.user.setEmail(email)
  Crisp.chat.show()
}

export function unloadCrisp() {
  Crisp.session.reset()
  Crisp.chat.hide()
}

export function showCrisp() {
  Crisp.chat.open()
}
