import axios from "axios"

export const axiosCore = axios.create({
  baseURL: "https://core.unipile.com",
  headers: { "Content-Type": "application/json" },
})

export const axiosCore2 = axios.create({
  baseURL: "https://stats.unipile.com",
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
})

export const axiosApi = axios.create({
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
})

export function setInstanceBaseUrl(
  https: boolean,
  server: string,
  port: number,
  dev: boolean
) {
  if (dev) {
    axiosApi.defaults.baseURL = `http${
      https ? "s" : ""
    }://${server}:${port}/api/v1`
  } else {
    axiosApi.defaults.baseURL = `http${https ? "s" : ""}://${server}/api/v1`
    axiosApi.defaults.params = { port }
  }
}

export function resetInstanceBaseUrl() {
  axiosApi.defaults.baseURL = ``
  axiosApi.defaults.params = {}
}
