import { isDev } from "@/utils/isDev"
import { AxiosError } from "axios"

import { NoInstanceRunning } from "@/config/errors"
import { axiosApi } from "@/lib/axios"
import dayjs from "@/lib/dayjs"

export type Account = {
  id: string
  name: string
  object: "Account"
  type: string
  sources: {
    id: string
    status: string
  }[]
  connection_params: {
    im?: {
      phone_number?: string
      id?: string
      username?: string
    }
    mail?: {
      imap_user?: string
    }
  }
  created_at: string
}

export type AccountListResponse = {
  cursor: string | null
  object: "AccountList"
  items: Account[]
}

export async function getAccountList({
  pageParam = undefined,
}: {
  pageParam?: string
}) {
  try {
    const { data } = await axiosApi.get(`/accounts`, {
      params: { limit: 250, ...(pageParam && { cursor: pageParam }) },
    })

    return data as AccountListResponse
  } catch (err) {
    if (err instanceof AxiosError && err.message === "Network Error")
      throw new NoInstanceRunning()
    throw err
  }
}

type HostedAuthLinkCreationResponse = {
  object: "HostedAuthURL"
  url: string
}

export async function getAccountAuthLink() {
  // Remove /api/v2 at the end of baseURL
  let api_url = axiosApi.defaults.baseURL?.slice(0, -7)

  if (!isDev) api_url += ":" + axiosApi.defaults.params.port

  const { data } = await axiosApi.post("/hosted/accounts/link", {
    type: "create",
    providers: "*",
    expiresOn: dayjs().add(10, "m").toISOString(),
    api_url,
    success_redirect_url: window.location.href,
    failure_redirect_url: window.location.href,
    // sync_limit: {
    //   MAILING: "NO_HISTORY_SYNC",
    // },
  })

  return data as HostedAuthLinkCreationResponse
}

export async function getAccountReconnectLink(id: string) {
  // Remove /api/v2 at the end of baseURL
  let api_url = axiosApi.defaults.baseURL?.slice(0, -7)

  if (!isDev) api_url += ":" + axiosApi.defaults.params.port

  const { data } = await axiosApi.post("/hosted/accounts/link", {
    type: "reconnect",
    reconnect_account: id,
    expiresOn: dayjs().add(10, "m").toISOString(),
    api_url,
    success_redirect_url: window.location.href,
    failure_redirect_url: window.location.href,
  })

  return data as HostedAuthLinkCreationResponse
}

export async function deleteAccount(id: string) {
  const { data } = await axiosApi.delete(`/accounts/${id}`)

  return data as { object: "AccountDeleted" }
}

export async function restartAccount(id: string) {
  const { data } = await axiosApi.post(`/accounts/${id}/restart`)

  return data as { object: "AccountRestarted" }
}
