import { axiosApi } from "@/lib/axios"

type AccessTokenScopes = { [key: string]: "*" | ("Read" | "Write")[] }

export type AccessToken = {
  id: string
  prefix: string
  name: string
  username: string
  scopes: AccessTokenScopes
  issuedAt: string
  expiresOn: string
}

export type AccessTokenListResponse = {
  object: "AccessTokenList"
  items: (AccessToken & { object: "AccessToken" })[]
}

export async function getAccessTokenList() {
  const { data } = await axiosApi.get(`/access_tokens`)

  return data as AccessTokenListResponse
}

export type AccessTokenCreatedResponse = AccessToken & {
  object: "AccessTokenCreated"
  token: string
}

export async function createAccessToken(body: {
  name: string
  scopes: { [key: string]: string | string[] }
  expiresOn: string
}) {
  const { data } = await axiosApi.post(`/access_tokens`, body)

  return data as AccessTokenCreatedResponse
}

export async function revokeAccessToken(id: string) {
  const { data } = await axiosApi.delete(`/access_tokens/${id}`)

  return
}

async function wait() {
  return await new Promise((r) => setTimeout(r, 1000))
}
