import { useEffect } from "react"
import * as Sentry from "@sentry/react"
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

if (!import.meta.env.DEV)
  Sentry.init({
    dsn: "https://de972cac1a0c5b9d5c5bed1353ce5048@o467030.ingest.sentry.io/4506104594694144",
    release: `unipile@${APP_VERSION}`,
    environment: import.meta.env.MODE,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
  })

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export function setSentryUser(email: string) {
  Sentry.setUser({ email })
}

export function clearSentryUser() {
  Sentry.setUser(null)
}
