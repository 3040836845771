import { useEffect, useMemo, useState } from "react"
import {
  assignUserInstance,
  getUserInstance,
  openInstanceSession,
} from "@/api/instance"
import { getUser } from "@/api/user"
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Input,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { ExternalLink } from "lucide-react"
import { useSearchParams } from "react-router-dom"

import { PlansConfig } from "@/config/plans"
import { setInstanceBaseUrl } from "@/lib/axios"
import { showCrisp } from "@/lib/crisp"
import { useAuth, useAuthDispatch } from "@/hooks/useAuth"
import { useSubscriptionStatus } from "@/hooks/useSubscriptionStatus"
import StripeTable from "@/components/StripeTable"

const meetingLink =
  "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0LnMQYeQh5cmBpfEZtbk-yAMkUzjVH8LIzUm0DQjV6MermXH3jVQ4hmoJgYfBLCwEFCiQlEgEV?gv=true"

export default function PlansPage() {
  // const [selected, setSelected] = useState<number>(50)
  // const plan = PlansConfig.find((plan) => plan.accounts === selected)

  const auth = useAuth()
  const setAuth = useAuthDispatch()

  const [searchParams, setSeachParams] = useSearchParams()

  const isRedirectedSuccess = searchParams.has("success")
  const { isSubscriptionActive } = useSubscriptionStatus()

  /**
   * On Stripe redirection, check user at interval to refresh the session automatically when updated
   */
  useEffect(() => {
    let checkSubscriptionInterval: NodeJS.Timeout

    if (
      auth &&
      (!auth.subscription_id || !auth.offer_value) &&
      isRedirectedSuccess
    ) {
      checkSubscriptionInterval = setInterval(async () => {
        const user = await getUser(auth.id, auth.token)

        if (user.offer_value)
          setAuth({
            ...auth,
            subscription_id: user.subscription_id,
            offer_value: user.offer_value,
          })
      }, 3000)
    } else {
      setSeachParams({})
    }

    return () => {
      checkSubscriptionInterval && clearInterval(checkSubscriptionInterval)
    }
  }, [isRedirectedSuccess, auth])

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        mb={12}
      >
        <Stack>
          <Typography variant="h3">Subscription</Typography>
          <Paper sx={{ p: 8, px: 12, mt: 6 }}>
            <Typography variant="body1" fontWeight="medium" textAlign="center">
              {isSubscriptionActive ? (
                <>
                  You currently have an <strong>active subscription</strong>.
                  You can manage it and update your billing information on
                  Stripe.
                </>
              ) : (
                <>
                  You must subscribe to our offer to continue. You don't pay
                  anything today. You can cancel free of charge within 30 days.
                  No commitment.
                </>
              )}{" "}
              If you would like to speak with our team,{" "}
              <Link
                sx={{ fontSize: 16, color: "info.light", cursor: "pointer" }}
                onClick={() => showCrisp()}
              >
                use chat
              </Link>{" "}
              or{" "}
              <Link
                sx={{ fontSize: 16, color: "info.light" }}
                component="a"
                href={meetingLink}
                target="_blank"
              >
                book a meeting
              </Link>
            </Typography>
          </Paper>
        </Stack>
      </Stack>
      {isRedirectedSuccess && !isSubscriptionActive ? (
        <Alert severity="info" variant="filled">
          <Stack direction="row" alignItems="center" spacing={4}>
            <CircularProgress size={20} color="inherit" />
            <div>
              <AlertTitle>Please wait</AlertTitle>
              We are updating your subscription
            </div>
          </Stack>
        </Alert>
      ) : isSubscriptionActive ? (
        <Button
          target="_blank"
          variant="contained"
          href="https://billing.stripe.com/p/login/3cs3exfZE8xncbSdQQ"
          endIcon={<ExternalLink size={14} />}
        >
          Manage subscription on Stripe
        </Button>
      ) : (
        auth && (
          <Stack direction="row" alignItems="flex-start" mt={8} spacing={12}>
            <Stack width="100%" spacing={4}>
              <Stack direction="row" alignItems="center" pl={2}>
                <Typography variant="body2" sx={{ width: "50%", pl: 4 }}>
                  Connected accounts
                </Typography>

                <Typography variant="body2" sx={{ width: "50%" }}>
                  Monthly price
                </Typography>
              </Stack>
              {PlansConfig.map((plan) => (
                <Paper
                  key={plan.accounts}
                  sx={{
                    cursor: "pointer",
                    borderColor: "divider",
                    backgroundColor: "background.default",
                  }}
                >
                  <Stack direction="row" alignItems="center" py={2} pl={2}>
                    <Typography variant="body2" sx={{ width: "50%", pl: 4 }}>
                      {plan.accounts}
                    </Typography>

                    <Typography variant="body2" sx={{ width: "50%" }}>
                      {plan.price}
                    </Typography>
                  </Stack>
                </Paper>
              ))}
            </Stack>

            <Stack width={400} spacing={4}>
              <StripeTable id={auth.id} email={auth.email} />
            </Stack>
          </Stack>
        )
      )}
      <Typography variant="subtitle1" color="text.secondary" mt={8}>
        Pricing is based on the number of linked accounts. <br />
        For example, 3 Emails + 2 LinkedIn + 6 WhatsApp accounts would cost 55€
        (11 x 5€) (or $60.5, 11 x $5.5). <br />
        Charges are based on the peak number of accounts simultaneously
        linked during the billing period, with all accounts listed on the
        dashboard home regardless of their status.
      </Typography>
      {/* <Stack pt={12}>
        <Typography variant="h3">Cost estimation</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Calculate your cost
        </Typography>
      </Stack>
      <CostEstimator /> */}
    </>
  )
}

function CostEstimator() {
  const [value, setValue] = useState(10)

  const cost = useMemo(() => {
    if (value <= 10) return 49
    if (value <= 50) return value * 5
    if (value <= 200) return value * 4.8
    if (value <= 1000) return value * 4
    if (value <= 5000) return value * 3.5
    return value * 3
  }, [value])

  return (
    <>
      <Input
        type="number"
        value={value}
        onChange={(e) => {
          setValue(+e.target.value)
        }}
      />
      <Typography>{cost}€</Typography>
    </>
  )
}
