import React, { useEffect, useState } from "react"

export function useLocalStorage<T>(
  key: string,
  defaultValue: T,
  session?: boolean
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    let currentValue

    try {
      currentValue = JSON.parse(
        (session ? sessionStorage : localStorage).getItem(key) ||
          String(defaultValue)
      )
    } catch (error) {
      currentValue = defaultValue
    }

    return currentValue
  })

  useEffect(() => {
    ;(session ? sessionStorage : localStorage).setItem(
      key,
      JSON.stringify(value)
    )
  }, [value, key, session])

  return [value, setValue]
}
