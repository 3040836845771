import jwtDecode from "jwt-decode"

import { axiosCore } from "@/lib/axios"
import { WebCrypto } from "@/lib/crypto/WebCrypto"

export type SignInResponse = {
  refresh_token: string
  token: string
}

export async function authenticateUser(email: string, password: string) {
  const encrypted_password = await WebCrypto.getStopGapPasswordHash(
    password,
    email
  )
  const response = await axiosCore.post("/api/login_check", {
    username: email,
    password: encrypted_password,
  })

  return response.data as SignInResponse
}

export async function getUser(id: string, token: string) {
  const response = await axiosCore.get(`/api/user/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return response.data as JWT
}

export type RegisterResponse = { new_id: string }

export async function registerUser({
  email,
  password,
  ...rest
}: {
  email: string
  password: string
  firstname: string
  lastname: string
}) {
  const encrypted_password = await WebCrypto.getStopGapPasswordHash(
    password,
    email
  )

  const response = await axiosCore.post("/api/user/registration", {
    email,
    password: encrypted_password,
    referral_code: "T66hhzotFoBNGiqoLzfg6U",
    account_type: "API",
    ...rest,
  })

  return response.data as RegisterResponse
}

export async function updateUser(
  id: string,
  params: Record<string, string | boolean | number>,
  token: string
) {
  return axiosCore.put(
    `/api/user/${id}`,
    { params },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export type JWT = {
  id: string
  firstname: string
  lastname: string
  email: string
  params?: { [key: string]: string }
  subscription_id: string | null
  offer_value: number
}

export function decodeToken(token: string): JWT {
  const decoded = jwtDecode<JWT>(token)

  return decoded
}
