import { PropsWithChildren } from "react"
import { ConfirmProvider as MuiConfirmProvider } from "material-ui-confirm"

function ConfirmProvider({ children }: PropsWithChildren<{}>) {
  return (
    <MuiConfirmProvider
      defaultOptions={{
        dialogProps: {
          maxWidth: "xs",
          onClose: () => {},
        },
        confirmationButtonProps: {
          variant: "contained",
        },
        cancellationButtonProps: {
          variant: "outlined",
        },
        titleProps: {
          variant: "h4",
          textAlign: "center",
        },
        allowClose: false,
      }}
    >
      {children}
    </MuiConfirmProvider>
  )
}

export default ConfirmProvider
