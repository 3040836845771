import { useContext } from "react"
import { AuthDispatchContext, AuthStateContext } from "@/context/AuthProvider"

export function useAuth() {
  return useContext(AuthStateContext)
}

export function useAuthDispatch() {
  return useContext(AuthDispatchContext)
}
