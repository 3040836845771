import { useEffect } from "react"
import { getUser } from "@/api/user"
import { useLocation } from "react-router-dom"

import { useAuth, useAuthDispatch } from "./useAuth"

/**
 * Refresh user session on route change to detect subscription changes more frequently
 */
export function useRefreshSession() {
  const auth = useAuth()
  const setAuth = useAuthDispatch()
  const location = useLocation()

  useEffect(() => {
    if (!auth || !auth.id || !auth.token) return
    getUser(auth.id, auth.token).then((user) => {
      setAuth((prev) => {
        if (prev)
          return {
            ...prev,
            offer_value: user.offer_value,
            subscription_id: user.subscription_id,
          }
        return null
      })
    })
  }, [auth?.id, auth?.token, location.pathname])
}
