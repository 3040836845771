import {
  AccessToken,
  getAccessTokenList,
  revokeAccessToken,
} from "@/api/access-token"
import {
  Alert,
  Button,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { useConfirm } from "material-ui-confirm"
import { enqueueSnackbar } from "notistack"

import dayjs from "@/lib/dayjs"
import AccessTokenForm from "@/components/AccessTokenForm"
import ErrorAlert from "@/components/ErrorAlert"

export default function AccessTokenPage() {
  return (
    <Stack spacing={10}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack>
          <Typography variant="h3">Access tokens</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Manage tokens that grant access the API.
          </Typography>
        </Stack>
        <AccessTokenForm />
      </Stack>
      <TokensList />
    </Stack>
  )
}

function TokensList() {
  const { isLoading, error, data, isError } = useQuery({
    queryKey: ["access_tokens"],
    queryFn: getAccessTokenList,
  })

  if (isLoading)
    return (
      <Paper>
        <Stack divider={<Divider />}>
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
        </Stack>
      </Paper>
    )

  if (isError) return <ErrorAlert error={error} />

  if (data.items.length > 0)
    return (
      <Paper>
        <Stack divider={<Divider />}>
          {data.items
            .sort((a, b) => (dayjs(a.issuedAt).isBefore(b.issuedAt) ? 1 : -1))
            .map((item) => (
              <TokenItem key={item.id} token={item} />
            ))}
        </Stack>
      </Paper>
    )

  return (
    <Typography variant="h4" textAlign="center" pt={10}>
      You don&apos;t have any access tokens.
    </Typography>
  )
}

function TokenItem({ token }: { token: AccessToken }) {
  const expiresIn = dayjs(token.expiresOn).diff(dayjs(), "days")
  const expiringColor =
    expiresIn > 100
      ? "text.secondary"
      : expiresIn < 50
      ? "error.main"
      : "warning.main"

  return (
    <Stack
      p={4}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={4}
    >
      <Stack width={200}>
        <Typography variant="body2">{token.name}</Typography>
        <Typography variant="body2" color={expiringColor}>
          Expires in {expiresIn} days
        </Typography>
      </Stack>
      <Stack flex={1} alignItems="flex-start" overflow="hidden">
        <Typography variant="subtitle1" color="text.secondary" noWrap>
          {Object.entries(token.scopes)
            .map(([key, val]) => `${key}:${val}`)
            .join(", ")}
        </Typography>
      </Stack>
      <Stack width={200} direction="row" spacing={1}>
        <code style={{ fontSize: 14 }}>{token.prefix}...</code>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={8}>
        <Typography variant="body2" color="text.secondary">
          Issued at {dayjs(token.issuedAt).format("L")}
        </Typography>
        <RevokeButton token={token} />
      </Stack>
    </Stack>
  )
}

function RevokeButton({ token }: { token: AccessToken }) {
  const confirm = useConfirm()
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationFn: revokeAccessToken,
    onSuccess: (data) => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["access_tokens"] })
        enqueueSnackbar("Token revoked", { variant: "default" })
      }, 300)
    },
    onError: () => {
      enqueueSnackbar("Unexpected error", {
        variant: "error",
        title: "Can't revoke token",
      })
    },
  })
  const handleClick = () => {
    confirm({
      title: "Revoke token",
      content: (
        <Alert severity="warning" variant="outlined">
          You will be unable to perform any more actions with this token.
        </Alert>
      ),
      confirmationButtonProps: {
        color: "error",
      },
      confirmationText: "Revoke token",
    }).then(() => {
      mutate(token.id)
    })
  }

  return (
    <Button variant="outlined" color="error" onClick={handleClick}>
      Revoke
    </Button>
  )
}

function SkeletonItem() {
  return (
    <Stack
      p={4}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={4}
    >
      <Stack width={200}>
        <Skeleton />
        <Skeleton height={19} width="60%" />
      </Stack>
      <Stack flex={1} alignItems="flex-start" overflow="hidden">
        <Skeleton width={200} />
      </Stack>
      <Stack width={200} direction="row" spacing={1}>
        <Skeleton width={150} />
      </Stack>
      <Stack direction="row" alignItems="center" spacing={8}>
        <Skeleton width={150} />
        <Skeleton variant="rounded" width={75} height={36} />
      </Stack>
    </Stack>
  )
}
