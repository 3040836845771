import { IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { X } from "lucide-react"

import { useLocalStorage } from "@/hooks/useLocalStorage"

import { PaperDraft } from "./PaperDraft"

export function Tutorial({
  id,
  title,
  subtitle,
  Buttons,
  closable,
}: {
  id: string
  title: string
  subtitle: string
  Buttons: React.ReactNode
  closable?: boolean
}) {
  const [hide, setHide] = useLocalStorage<boolean>("hide_" + id, false)

  if (hide) return null
  return (
    <PaperDraft sx={{ mb: 10 }}>
      {closable && (
        <Tooltip title="Dismiss help">
          <IconButton
            onClick={() => setHide(true)}
            sx={{ position: "absolute", right: 10, top: 10 }}
          >
            <X size={16} />
          </IconButton>
        </Tooltip>
      )}
      <Stack
        direction="row"
        alignItems="center"
        // justifyContent="center"
        spacing={10}
        p={11}
      >
        <Stack alignItems="flex-start">
          <Typography variant="h6">{title}</Typography>
          <Typography
            variant="subtitle1"
            textAlign="left"
            color="text.secondary"
            sx={{ maxWidth: 400, mb: 6 }}
          >
            {subtitle}
          </Typography>
          <Stack direction="row" spacing={2}>
            {Buttons}
          </Stack>
        </Stack>
      </Stack>
    </PaperDraft>
  )
}
