import React from "react"

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

export default function StripeTable({
  id,
  email,
}: {
  id: string
  email: string
}) {
  return (
    <stripe-pricing-table
      pricing-table-id="prctbl_1OH577GEyHW8rucEgpJviGDk"
      publishable-key="pk_live_51HdtaGGEyHW8rucE1om5wZMnVzjyaYCzlRpeNuVZTAGcrpz82jXsH78ii2rsIyVtG5cRIECo4JMaIHISNgEcYd3200MdJ5J3LA"
      client-reference-id={id}
      customer-email={email}
    ></stripe-pricing-table>
  )
}
