import {
  CreditCard,
  KeyRound,
  LucideIcon,
  Settings,
  UserCircle2,
  Webhook,
} from "lucide-react"

type MenuItem = {
  title: string
  href: string
  Icon: LucideIcon
}

export const AppMenuConfig: MenuItem[] = [
  {
    title: "Accounts",
    href: "accounts",
    Icon: UserCircle2,
  },
  {
    title: "Webhooks",
    href: "webhooks",
    Icon: Webhook,
  },
  {
    title: "Access Tokens",
    href: "access-tokens",
    Icon: KeyRound,
  },
  {
    title: "Settings",
    href: "settings",
    Icon: Settings,
  },
]

export const OrgMenuConfig: MenuItem[] = [
  {
    title: "Subscription",
    href: "plans",
    Icon: CreditCard,
  },
]

export const MENU_WIDTH = 250
