import React, { isValidElement, PropsWithChildren } from "react"
import {
  Alert,
  AlertTitle,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import {
  CustomContentProps,
  SnackbarProvider as NotistackProvider,
  SnackbarContent,
} from "notistack"

/**
 * Declare a new property that can be given when using enqueueSnackbar
 */
declare module "notistack" {
  interface VariantOverrides {
    error: {
      title?: string
    }
    default: {
      title?: string
    }
    success: {
      title?: string
    }
    warning: {
      title?: string
    }
    info: {
      title?: string
    }
  }
}

interface CustomSnackbarProps extends CustomContentProps {
  title?: string
}
/**
 * Default snackback using Paper component
 */
const Default = React.forwardRef<HTMLDivElement, CustomSnackbarProps>(
  function DefaultSnackbar({ id, message, title, action, ...other }, ref) {
    const theme = useTheme()

    // Force color and variant outlined props on the given action button to make it look good
    const Button = isValidElement(action) ? (
      <action.type {...action.props} variant="outlined" />
    ) : null

    return (
      <SnackbarContent ref={ref} {...other}>
        <Paper
          sx={{
            width: "100%",
            px: 6,
            py: 6,
            boxShadow: theme.shadows[3],
          }}
        >
          <Stack direction="row" alignItems="center" spacing={4}>
            <div>
              {title && (
                <Typography variant="subtitle2" gutterBottom>
                  {title}
                </Typography>
              )}
              <Typography variant="subtitle1">{message}</Typography>
            </div>
            {Button}
          </Stack>
        </Paper>
      </SnackbarContent>
    )
  }
)

/**
 * Other variants using Alert Component
 */
const AlertSnackbar = React.forwardRef<HTMLDivElement, CustomSnackbarProps>(
  function ErrorSnackbar(
    { id, message, title, action, className, style, ...other },
    ref
  ) {
    const theme = useTheme()

    // For typing, there is no reason to get a default variant here
    if (other.variant === "default") return null

    const comp: React.ReactNode =
      typeof action === "function" ? action(id) : action

    // Force color and variant outlined props on the given action button to make it look good
    const Button = isValidElement(comp) ? (
      <comp.type {...comp.props} color="inherit" variant="outlined" />
    ) : null

    console.log(other.variant)
    return (
      <SnackbarContent ref={ref} className={className} style={style}>
        <Alert
          severity={other.variant}
          variant="filled"
          sx={{
            width: "100%",
            py: 4,
            px: 6,
            boxShadow: theme.shadows[3],
          }}
          action={Button}
        >
          {title && <AlertTitle sx={{ mb: 1 }}>{title}</AlertTitle>}
          {message}
        </Alert>
      </SnackbarContent>
    )
  }
)

function SnackbarProvider({ children }: PropsWithChildren<{}>) {
  return (
    <NotistackProvider
      style={{ maxWidth: 500 }}
      Components={{
        default: Default,
        error: AlertSnackbar,
        info: AlertSnackbar,
        success: AlertSnackbar,
        warning: AlertSnackbar,
      }}
      autoHideDuration={4000}
      transitionDuration={100}
      maxSnack={3}
      TransitionProps={{ direction: "up" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      variant="success"
    >
      {children}
    </NotistackProvider>
  )
}

export default SnackbarProvider
