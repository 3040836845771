import * as React from "react"
import {
  Box,
  Button,
  Divider,
  Link as MuiLink,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import OutlinedInput from "@mui/material/OutlinedInput"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { CheckCircle, CheckCircle2 } from "lucide-react"
import { enqueueSnackbar } from "notistack"
import { Controller, useForm } from "react-hook-form"
import { Link } from "react-router-dom"

import { useSignUp } from "@/hooks/useSignUp"

export default function SignUpPage() {
  const matches = useMediaQuery("(min-width:550px)")
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent={matches ? "center" : "flex-start"}
      height="100vh"
      spacing={2}
      sx={{
        minHeight: 1100,
      }}
    >
      <Stack
        alignItems="flex-start"
        sx={{ maxWidth: 550, width: "100%" }}
        spacing={6}
        p={6}
      >
        <Stack>
          <img src="/logo.svg" height={30} />
          <Typography variant="caption">Dashboard</Typography>
        </Stack>
        <Divider sx={{ width: "100%" }} />
        <SignUpForm />
      </Stack>

      <Typography variant="body2">
        Already have an account ? <Link to="/login">Log in</Link>
      </Typography>
    </Stack>
  )
}

interface SignUpFormData {
  firstname: string
  lastname: string
  email: string
  password: string
  password_repeat: string
  company_name: string
  role: string
  apis: string[]
}

export function SignUpForm() {
  const { isLoading, mutate: signUp, isSuccess } = useSignUp()
  const { register, handleSubmit, control, setValue, watch } =
    useForm<SignUpFormData>({
      defaultValues: {
        apis: [],
      },
    })

  register("apis")

  if (isSuccess)
    return (
      <Stack py={20} alignItems="center" spacing={4} width="100%">
        <CheckCircle2 color="green" size={40} strokeWidth={1.5} />
        <Typography variant="h3">Your registration is complete</Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          You can now use your credentials to login to the Dashboard.
        </Typography>
        <Button component={Link} to="/" variant="contained" size="large">
          Go to dashboard
        </Button>
      </Stack>
    )

  return (
    <Stack
      id="signup"
      component="form"
      onSubmit={handleSubmit(
        async ({ password_repeat, ...data }) => {
          signUp(data)
        },
        (fields) => {
          if (
            Object.values(fields).some(
              (field) => !Array.isArray(field) && field.type === "required"
            )
          )
            enqueueSnackbar("Make sure you filled all fields", {
              variant: "error",
            })
          if (
            Object.values(fields).some(
              (field) => !Array.isArray(field) && field.type === "pattern"
            )
          )
            enqueueSnackbar("Please check your email", {
              variant: "error",
            })

          if (fields.password_repeat?.type === "validate")
            enqueueSnackbar(fields.password_repeat.message, {
              variant: "error",
            })
        }
      )}
      width="100%"
      spacing={12}
    >
      <div>
        <Typography variant="h3">Create an API account</Typography>
        <Typography variant="caption">
          Only for Software Publishers and Developers
        </Typography>
      </div>

      <Stack spacing={3}>
        <Typography variant="h6">
          <CheckCircle
            color="#62AE78"
            style={{ verticalAlign: "text-bottom" }}
          />{" "}
          Get immediate access to Unipile Messaging API.
        </Typography>
      </Stack>

      <Stack spacing={4}>
        <Stack direction="row" spacing={4}>
          <Box flex={1}>
            <Typography variant="body2">First name</Typography>
            <TextField
              name="firstname"
              inputRef={register({
                required: "Required",
              })}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <Typography variant="body2">Last name</Typography>
            <TextField
              name="lastname"
              inputRef={register({
                required: "Required",
              })}
              fullWidth
            />
          </Box>
        </Stack>
        <Stack direction="row" spacing={4}>
          <Box flex={1}>
            <Typography variant="body2">Company name</Typography>
            <TextField
              name="company_name"
              inputRef={register({
                required: "Required",
              })}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <Typography variant="body2">Role</Typography>
            <TextField
              name="role"
              inputRef={register({
                required: "Required",
              })}
              fullWidth
            />
          </Box>
        </Stack>
        <ApiSelect
          onChange={(value) => {
            setValue("apis", value)
          }}
        />
      </Stack>
      <Stack spacing={4}>
        <Box flex={1}>
          <Typography variant="body2">Email</Typography>
          <TextField
            autoComplete="email"
            name="email"
            inputRef={register({
              required: "Required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid",
              },
            })}
            fullWidth
          />
        </Box>
        <div>
          <Typography variant="body2">Password</Typography>
          <TextField
            autoComplete="new-password"
            name="password"
            type={"password"}
            inputRef={register({ required: "Required" })}
            fullWidth
          />
        </div>
        <div>
          <Typography variant="body2">Confirm password</Typography>
          <TextField
            name="password_repeat"
            type={"password"}
            inputRef={register({
              required: "Required",
              validate: (val: string) => {
                if (watch("password") != val) {
                  return "Your passwords do no match"
                }
              },
            })}
            fullWidth
          />
        </div>
      </Stack>
      <Button
        variant="contained"
        type="submit"
        disabled={isLoading}
        sx={{ mt: 10 }}
        size="large"
      >
        {isLoading ? "Loading..." : "Create API account"}
      </Button>
      <Typography variant="body2" color="text.secondary" align="center">
        By signing up, you create a Unipile account and you agree to Unipile's{" "}
        <MuiLink
          href="https://www.unipile.com/terms-of-use/"
          target="_blank"
          rel="noreferrer"
        >
          Terms of service
        </MuiLink>{" "}
        and{" "}
        <MuiLink
          href="https://www.unipile.com/privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </MuiLink>{" "}
      </Typography>
    </Stack>
  )
}

const ITEM_HEIGHT = 42
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const names = [
  "LinkedIn",
  "WhatsApp",
  "Instagram",
  "Facebook Messenger",
  "Telegram",
  "Email",
  "Calendar",
]

function ApiSelect({ onChange }: { onChange: (value: string[]) => void }) {
  const [apiName, setApiName] = React.useState<string[]>([])

  const handleChange = (event: SelectChangeEvent<typeof apiName>) => {
    const {
      target: { value },
    } = event

    const updatedValue = typeof value === "string" ? value.split(",") : value
    setApiName(updatedValue)
    onChange(updatedValue)
  }

  return (
    <div>
      <Typography variant="body2">APIs</Typography>
      <Select
        multiple
        value={apiName}
        onChange={handleChange}
        input={<OutlinedInput label="Tag" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
        fullWidth
        size="small"
      >
        {names.map((name) => (
          <MenuItem key={name} value={name} dense>
            <Checkbox size="small" checked={apiName.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}
