import { WebhookTriggerEvent } from "@/api/webhook"
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react"

export type WebhookFormData = {
  name?: string
  source?: "messaging" | "account_status" | "email" | "email_tracking"
  events?: WebhookTriggerEvent[]
  selection?: "all" | "custom"
  accounts?: string[]
  url?: string
}

type WebhookFormContext = {
  data: WebhookFormData
  setData: Dispatch<SetStateAction<WebhookFormData>>
}

export const WebhookFormContext = createContext<WebhookFormContext>({
  data: {},
  setData: () => {},
})

function WebhookFormProvider({ children }: PropsWithChildren<{}>) {
  const [data, setData] = useState<WebhookFormData>({})

  return (
    <WebhookFormContext.Provider value={{ data, setData }}>
      {children}
    </WebhookFormContext.Provider>
  )
}

export default WebhookFormProvider
