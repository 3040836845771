import React, { useState } from "react"
import {
  Account,
  deleteAccount,
  getAccountAuthLink,
  getAccountList,
} from "@/api/account"
import {
  Alert,
  AlertTitle,
  Avatar,
  Badge,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Link as MuiLink,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import {
  AlertCircle,
  BookOpen,
  Fingerprint,
  InfoIcon,
  KeyRound,
  ListChecks,
  MoreHorizontal,
  PlusCircle,
  Webhook,
} from "lucide-react"
import { useConfirm } from "material-ui-confirm"
import { enqueueSnackbar } from "notistack"
import { Link, Navigate } from "react-router-dom"

import { NoInstanceRunning } from "@/config/errors"
import {
  LoadMoreButton,
  ReconnectButton,
  RestartButton,
} from "@/components/Buttons"
import { Copyable } from "@/components/Copyable"
import ErrorAlert from "@/components/ErrorAlert"
import {
  DotConnecting,
  DotDisconnected,
  DotRunning,
  DotStopped,
} from "@/components/StatusDot"
import { Tutorial } from "@/components/Tutorial"

export default function AccountListPage() {
  return (
    <>
      <Stack direction="row" alignItems="flex-start" mb={2} spacing={2}>
        <Stack flex={1}>
          <Typography variant="h3">Accounts</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Manage accounts connected to your app.
          </Typography>
        </Stack>
        <Button
          startIcon={<BookOpen size={16} />}
          variant="outlined"
          component="a"
          href="https://developer.unipile.com/docs/connect-accounts"
          target="_blank"
        >
          Documentation
        </Button>
        <Button
          variant="contained"
          onClick={async () => {
            getAccountAuthLink()
              .then((res) => window.location.replace(res.url))
              .catch((err) => {
                enqueueSnackbar(err.message, {
                  title: "Unexpected error",
                  variant: "error",
                })
              })
          }}
        >
          Connect an account
        </Button>
      </Stack>
      <AccountsList />
    </>
  )
}

function AccountsList() {
  const {
    isLoading,
    error,
    data,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["accounts"],
    queryFn: getAccountList,
    getNextPageParam: (lastPage) => lastPage.cursor,
  })

  if (isLoading) return <SkeletonList />
  if (isError) {
    if (error instanceof NoInstanceRunning) return <Navigate to="/plans" />
    return <ErrorAlert error={error} />
  }

  const running = data.pages.reduce((total, page) => {
    const countInPage = page.items.filter(
      (item) => item.sources[0]?.status === "OK"
    ).length
    return total + countInPage
  }, 0)

  const connecting = data.pages.reduce((total, page) => {
    const countInPage = page.items.filter(
      (item) => item.sources[0]?.status === "CONNECTING"
    ).length
    return total + countInPage
  }, 0)

  const disconnected = data.pages.reduce((total, page) => {
    const countInPage = page.items.filter(
      (item) => item.sources[0]?.status === "CREDENTIALS"
    ).length
    return total + countInPage
  }, 0)

  const stopped = data.pages.reduce((total, page) => {
    const countInPage = page.items.filter(
      (item) =>
        item.sources[0]?.status !== "OK" &&
        item.sources[0]?.status !== "CONNECTING" &&
        item.sources[0]?.status !== "CREDENTIALS"
    ).length
    return total + countInPage
  }, 0)

  return (
    <>
      <Stack direction="row" alignItems="center" mb={10} spacing={4}>
        <RunningLabel count={running} />
        <ConnectingLabel count={connecting} />
        <DisconnectedLabel count={disconnected} />
        <StoppedLabel count={stopped} />
        {hasNextPage && (
          <Tooltip title="Only listed accounts are counted here.">
            <AlertCircle color="red" size={20} />
          </Tooltip>
        )}
      </Stack>

      {data.pages[0] && data.pages[0].items.length > 0 ? (
        <>
          <Tutorial
            id="chats"
            title="🚀 Start with the Messaging API"
            subtitle="Generate your API Key and begin testing and integration to unlock the features you need."
            Buttons={
              <>
                <Button
                  startIcon={<KeyRound size={16} />}
                  variant="outlined"
                  component={Link}
                  to="/access-tokens"
                >
                  Setup API Key
                </Button>
                <Button
                  startIcon={<ListChecks size={16} />}
                  variant="outlined"
                  component="a"
                  href="https://developer.unipile.com/docs/list-provider-features"
                  target="_blank"
                >
                  See all features
                </Button>
                <Button
                  startIcon={<BookOpen size={16} />}
                  variant="outlined"
                  component="a"
                  href="https://developer.unipile.com/docs/send-messages"
                  target="_blank"
                >
                  Send messages
                </Button>
                <Button
                  startIcon={<BookOpen size={16} />}
                  variant="outlined"
                  component="a"
                  href="https://developer.unipile.com/docs/get-messages"
                  target="_blank"
                >
                  Get messages
                </Button>
                <Button
                  startIcon={<BookOpen size={16} />}
                  variant="outlined"
                  component="a"
                  href="https://developer.unipile.com/docs/send-email"
                  target="_blank"
                >
                  Send email
                </Button>
                <Button
                  startIcon={<BookOpen size={16} />}
                  variant="outlined"
                  component="a"
                  href="https://developer.unipile.com/docs/retrieving-emails"
                  target="_blank"
                >
                  Get emails
                </Button>
              </>
            }
            closable
          />
          <Paper>
            <Stack divider={<Divider />}>
              {data.pages.map((page) =>
                page.items.map((item) => (
                  <AccountItem account={item} key={item.id} />
                ))
              )}
            </Stack>
          </Paper>
        </>
      ) : (
        <Tutorial
          id="accounts"
          closable={false}
          title="⚡️ Connect your first account"
          subtitle="Connect accounts from many providers and authorizes your app to
            access account's chat, message and contact data."
          Buttons={
            <>
              <Button
                startIcon={<ListChecks size={16} />}
                variant="outlined"
                component="a"
                href="https://developer.unipile.com/docs/list-provider-features"
                target="_blank"
              >
                Discover supported features
              </Button>
              <Button
                startIcon={<BookOpen size={16} />}
                variant="outlined"
                component="a"
                href="https://developer.unipile.com/docs/connect-accounts"
                target="_blank"
              >
                Setup within your app
              </Button>
              <Button
                startIcon={<PlusCircle size={16} />}
                variant="outlined"
                onClick={async () => {
                  getAccountAuthLink()
                    .then((res) => window.location.replace(res.url))
                    .catch((err) => {
                      enqueueSnackbar(err.message, {
                        title: "Unexpected error",
                        variant: "error",
                      })
                    })
                }}
              >
                Connect now
              </Button>
            </>
          }
        />
      )}
      <LoadMoreButton
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onClick={() => fetchNextPage()}
      />
    </>
  )
}

function AccountItem({ account }: { account: Account }) {
  function renderStatus() {
    switch (account.sources[0]?.status) {
      case "OK":
        return <RunningLabel />
      case "CONNECTING":
        return <ConnectingLabel />
      case "CREDENTIALS":
        return <DisconnectedLabel />
      default:
        return <StoppedLabel />
    }
  }

  return (
    <Stack
      p={4}
      spacing={4}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Avatar
        variant="rounded"
        src={`/${account.type.toLowerCase()}.svg`}
        sx={{ width: 22, height: 22 }}
      />

      <Stack flex={1}>
        <Typography variant="body2" noWrap>
          {account.name}
        </Typography>
        {renderStatus()}
      </Stack>
      <Stack flex={1}>
        <Copyable
          value={account.id}
          placement="top-start"
          title={
            <>
              Copy <code style={{ fontWeight: "bold" }}>account_id</code> to
              Clipboard
            </>
          }
        >
          <code style={{ fontSize: 14, opacity: 0.5 }}>{account.id}</code>
        </Copyable>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={4}
        width={300}
      >
        {account.sources[0]?.status === "OK" ? (
          <Typography variant="body2" color="success.main">
            Connected on {new Date(account.created_at).toLocaleDateString()}
          </Typography>
        ) : account.sources[0]?.status === "CREDENTIALS" ? (
          <ReconnectButton account_id={account.id} />
        ) : account.sources[0]?.status !== "CONNECTING" ? (
          <RestartButton account_id={account.id} />
        ) : (
          <></>
        )}
        <ButtonMenu account={account} />
      </Stack>
    </Stack>
  )
}

function ButtonMenu({ account }: { account: Account }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreHorizontal size={21} />
      </IconButton>

      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <DeleteButton account={account} onDone={handleClose} />
      </Menu>
    </div>
  )
}

function DeleteButton({
  account,
  onDone,
}: {
  account: Account
  onDone: () => void
}) {
  const confirm = useConfirm()
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationFn: deleteAccount,
    onSuccess: (data) => {
      setTimeout(() => {
        enqueueSnackbar("Account deleted", { variant: "default" })
        queryClient.invalidateQueries({ queryKey: ["accounts"] })
      }, 1000)
    },
    onError: () => {
      enqueueSnackbar("Unexpected error", {
        variant: "error",
        title: "Can't delete account",
      })
    },
  })
  const handleDelete = () => {
    onDone()
    confirm({
      title: "Delete account",
      content: (
        <Alert severity="warning" variant="outlined">
          All data related to this account will be deleted.
        </Alert>
      ),
      confirmationButtonProps: {
        color: "error",
      },
      confirmationText: "Delete account",
    }).then(() => {
      mutate(account.id)
    })
  }

  return (
    <MenuItem onClick={handleDelete} sx={{ color: "error.main" }}>
      Delete account
    </MenuItem>
  )
}

function RunningLabel({ count }: { count?: number }) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <DotRunning />
      <Typography
        variant="subtitle1"
        color={count === 0 ? "text.secondary" : "success.main"}
      >
        {count} Running
      </Typography>
    </Stack>
  )
}

function ConnectingLabel({ count }: { count?: number }) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <DotConnecting />
      <Typography
        variant="subtitle1"
        color={count === 0 ? "text.secondary" : "info.main"}
      >
        {count} Connecting
      </Typography>
    </Stack>
  )
}

function StoppedLabel({ count }: { count?: number }) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <DotStopped />
      <Typography
        variant="subtitle1"
        color={count === 0 ? "text.secondary" : "error.main"}
      >
        {count} Stopped
      </Typography>
    </Stack>
  )
}

function DisconnectedLabel({ count }: { count?: number }) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <DotDisconnected />
      <Typography
        variant="subtitle1"
        color={count === 0 ? "text.secondary" : "warning.main"}
      >
        {count} Disconnected
      </Typography>
    </Stack>
  )
}

function SkeletonList() {
  return (
    <>
      <Stack direction="row" mb={10} spacing={4}>
        <Skeleton width={120} height={19} />
        <Skeleton width={120} height={19} />
        <Skeleton width={120} height={19} />
      </Stack>

      <Paper>
        <Stack divider={<Divider />}>
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
        </Stack>
      </Paper>
    </>
  )
}

function SkeletonItem() {
  return (
    <Stack
      p={4}
      spacing={4}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Skeleton variant="rounded" width={22} height={22} />
      <Stack flex={1}>
        <Skeleton width={200} />
        <Skeleton width={100} height={16} />
      </Stack>
      <Stack flex={1}>
        <Skeleton width={200} />
      </Stack>
      <Stack direction="row" alignItems="center" spacing={8}>
        <Skeleton width={200} />
        <Skeleton variant="rounded" width={35} height={35} />
      </Stack>
    </Stack>
  )
}
