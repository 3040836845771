import { PropsWithChildren } from "react"
import { CssBaseline, ThemeProvider as MuiThemeProvider } from "@mui/material"

import { lightTheme } from "@/lib/theme"

function ThemeProvider({ children }: PropsWithChildren<{}>) {
  return (
    <MuiThemeProvider theme={lightTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}

export default ThemeProvider
