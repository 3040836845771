import { PropsWithChildren } from "react"
import { Button, Stack, Typography } from "@mui/material"
import dayjs from "dayjs"
import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  useLocation,
} from "react-router-dom"

import { SentryRoutes } from "@/lib/sentry"
import { useAuth } from "@/hooks/useAuth"
import { useSubscriptionStatus } from "@/hooks/useSubscriptionStatus"

import AccessTokenPage from "./AccessTokenPage"
import AccountListPage from "./AccountListPage"
import LogInPage from "./LoginPage"
import PlansPage from "./PlansPage"
import Root from "./Root"
import SettingsPage from "./SettingsPage"
import SignUpPage from "./SignUpPage"
import WebhookPage from "./WebhookPage"

export default function Router() {
  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route
          path="*"
          element={
            <RequireAuth>
              <Root />
            </RequireAuth>
          }
        >
          <Route
            path="accounts"
            element={
              <RequireSubscription>
                <AccountListPage />
              </RequireSubscription>
            }
          />
          <Route
            path="webhooks"
            element={
              <RequireSubscription>
                <WebhookPage />
              </RequireSubscription>
            }
          />
          <Route
            path="access-tokens"
            element={
              <RequireSubscription>
                <AccessTokenPage />
              </RequireSubscription>
            }
          />
          <Route
            path="settings"
            element={
              <RequireSubscription>
                <SettingsPage />
              </RequireSubscription>
            }
          />
          <Route path="plans" element={<PlansPage />} />
          <Route path="*" element={<Navigate to="accounts" />} />
        </Route>
        <Route path="login" element={<LogInPage />} />
        <Route path="signup" element={<SignUpPage />} />
      </SentryRoutes>
    </BrowserRouter>
  )
}

function RequireAuth({ children }: PropsWithChildren<{}>) {
  const auth = useAuth()
  const location = useLocation()

  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return <>{children}</>
}

function RequireSubscription({ children }: PropsWithChildren<{}>) {
  const { canUseApi } = useSubscriptionStatus()

  if (canUseApi) return <>{children}</>

  return (
    <Stack alignItems="center" direction="column" mt={50}>
      <Typography variant="h2">
        You must subscribe to our offer to continue.
      </Typography>
      <Typography variant="body1" color="text.secondary">
        You don't pay anything today. You can cancel free of charge within 30
        days. No commitment.
      </Typography>
      <Button component={Link} to="/plans" variant="contained" sx={{ mt: 4 }}>
        See Subscription
      </Button>
    </Stack>
  )
}
