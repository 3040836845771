export const PlansConfig = [
  {
    accounts: "0 - 10",
    price: "49€ ($55)",
  },
  {
    accounts: "11 - 50",
    price: "5€ ($5,5) / Account",
  },
  {
    accounts: "51 - 200",
    price: "4,5€ ($5) / Account",
  },
  {
    accounts: "201 - 1000",
    price: "4€ ($4,5) / Account",
  },
  {
    accounts: "1001 - 5000",
    price: "3,5€ ($4) / Account",
  },
  {
    accounts: "5001+",
    price: "3€ ($3,5) / Account",
  },
]
