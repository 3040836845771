import { Fragment, useEffect, useState } from "react"
import { createAccessToken } from "@/api/access-token"
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { CheckCircle2, Clipboard } from "lucide-react"
import { enqueueSnackbar } from "notistack"
import { useForm } from "react-hook-form"
import { useWizard, Wizard } from "react-use-wizard"

import { TokenExpirationDates, TokenScopesConfig } from "@/config/token-form"
import dayjs from "@/lib/dayjs"
import { useDialog } from "@/hooks/useDialog"

import { Copyable } from "./Copyable"

export default function AccessTokenForm() {
  const { isDialogOpen, openDialog } = useDialog()

  const [token, setToken] = useState<string>("")
  return (
    <>
      <Button variant="contained" onClick={() => openDialog("new")}>
        Generate a token
      </Button>
      <Dialog open={isDialogOpen("new")} fullWidth maxWidth="sm">
        <Wizard>
          <Generate onSuccess={setToken} />
          <Success token={token} />
        </Wizard>
      </Dialog>
    </>
  )
}

const initialCheckedState: { [key: string]: { [key: string]: boolean } } = {}

TokenScopesConfig.forEach((scope) => {
  initialCheckedState[scope.name] = { "*": false }
  scope.permissions.forEach((perm) => {
    initialCheckedState[scope.name][perm.type] = false
  })
})

type AccessTokenFormData = {
  name: string
  expiresOn: string
}

function Generate({ onSuccess }: { onSuccess: (token: string) => void }) {
  const { nextStep } = useWizard()
  const { closeDialog } = useDialog()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation({
    mutationFn: createAccessToken,
    onSuccess: (data) => {
      onSuccess(data.token)
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["access_tokens"] })
      }, 300)
      nextStep()
    },
    onError: () => {
      enqueueSnackbar("Unexpected error", {
        variant: "error",
        title: "Can't generate token",
      })
    },
  })

  const { register, handleSubmit, errors, setValue } =
    useForm<AccessTokenFormData>()

  const [checked, setChecked] = useState(initialCheckedState)

  const onSubmit = async (data: AccessTokenFormData) => {
    const scopes: { [key: string]: string | string[] } = {}

    Object.keys(checked).forEach((scope) => {
      if (checked[scope]["*"]) return (scopes[scope] = "*")

      const truthyKeys = Object.entries(checked[scope])
        .filter(([permission, value]) => value)
        .map(([permission]) => permission)

      if (truthyKeys.length) scopes[scope] = truthyKeys
    })

    if (!Object.keys(scopes).length) {
      enqueueSnackbar("Select at least one scope permission.", {
        variant: "info",
      })
      return
    }

    mutate({
      ...data,
      scopes,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Stack spacing={8}>
          <Typography variant="h3" textAlign="center">
            Generate a token
          </Typography>

          <Stack>
            <Typography variant="body2">Note</Typography>
            <Typography variant="subtitle1" color="text.secondary" mb={2}>
              What is this token used for ?
            </Typography>
            <TextField
              inputRef={register({ required: true })}
              fullWidth
              name="name"
              helperText={errors.name?.message}
            />
          </Stack>
          <DateSelector onChange={(date) => setValue("expiresOn", date)} />
          <input
            type="hidden"
            name="expiresOn"
            ref={register({ required: true })}
          />
          <Stack>
            <Typography variant="body2">Select scopes</Typography>
            <Typography variant="subtitle1" color="text.secondary" mb={2}>
              Scopes define the permissions to access.
            </Typography>

            <Paper>
              <Stack divider={<Divider />}>
                {TokenScopesConfig.map((scope, index) => (
                  <Fragment key={index}>
                    <Stack direction="row" alignItems="center">
                      <Checkbox
                        size="small"
                        checked={checked[scope.name]["*"]}
                        onChange={(e, checked) =>
                          setChecked((prev) => ({
                            ...prev,
                            [scope.name]: { ...prev[scope.name], "*": checked },
                          }))
                        }
                      ></Checkbox>
                      <Typography variant="body2">{scope.name}</Typography>
                    </Stack>
                    {scope.permissions.map((permission) => (
                      <Stack
                        key={permission.type}
                        direction="row"
                        alignItems="center"
                        pl={5}
                        pr={2}
                      >
                        <Checkbox
                          size="small"
                          disabled={checked[scope.name]["*"]}
                          checked={
                            checked[scope.name][permission.type] ||
                            checked[scope.name]["*"]
                          }
                          onChange={(e, checked) =>
                            setChecked((prev) => ({
                              ...prev,
                              [scope.name]: {
                                ...prev[scope.name],
                                [permission.type]: checked,
                              },
                            }))
                          }
                        ></Checkbox>
                        <Typography variant="subtitle1">
                          {permission.type}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          ml="auto"
                        >
                          {permission.description}
                        </Typography>
                      </Stack>
                    ))}
                  </Fragment>
                ))}
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ mr: "auto" }}
          onClick={() => closeDialog("new")}
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          variant="contained"
          sx={{ alignSelf: "flex-end" }}
          type="submit"
        >
          {isLoading ? "Loading..." : "Generate"}
        </Button>
      </DialogActions>
    </form>
  )
}

function DateSelector({ onChange }: { onChange: (date: string) => void }) {
  const [selected, setSelected] = useState(30)

  useEffect(() => {
    onChange(dayjs().add(selected, "days").toISOString())
  }, [selected])

  return (
    <Stack>
      <Typography variant="body2" mb={2}>
        Expiration date
      </Typography>
      <Stack direction="row" spacing={4} alignItems="center">
        <TextField
          value={selected}
          onChange={(e) => setSelected(+e.target.value)}
          select
          SelectProps={{
            native: true,
          }}
        >
          {TokenExpirationDates.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
        {selected !== 0 ? (
          <Typography variant="subtitle1" color="text.secondary">
            Token will expires on{" "}
            <strong>{dayjs().add(selected, "days").format("L")}</strong>
          </Typography>
        ) : (
          <TextField
            defaultValue={dayjs().format("YYYY-MM-DD")}
            inputProps={{ min: dayjs().format("YYYY-MM-DD") }}
            type="date"
            onChange={(e) => {
              const date = dayjs(e.target.value)
              if (date.isValid()) onChange(date.toISOString())
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

function Success({ token }: { token: string }) {
  const { closeDialog } = useDialog()
  return (
    <>
      <DialogContent>
        <Box>
          <Stack spacing={1} mb={8}>
            <Typography variant="h3" textAlign="center">
              Your new token
            </Typography>
          </Stack>
          <Stack spacing={2} mb={8}>
            <Typography variant="body2">Keep your token safe</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Save and store this new key to a secure place, such as a password
              manager or secret store. You won&apos;t be able to see it again.
            </Typography>
          </Stack>
          <Copyable value={token}>
            <Paper sx={{ p: 4 }}>
              <code style={{ wordWrap: "break-word" }}>{token}</code>
            </Paper>
          </Copyable>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ alignSelf: "flex-end" }}
          onClick={() => closeDialog("new")}
        >
          Done
        </Button>
      </DialogActions>
    </>
  )
}
