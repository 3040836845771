import { useSearchParams } from "react-router-dom"

export function useDialog() {
  const [searchParams, setSearchParams] = useSearchParams()

  return {
    openDialog: (id: string) =>
      setSearchParams((p) => {
        p.set(id, "1")
        return p
      }),
    closeDialog: (id: string) =>
      setSearchParams((p) => {
        p.delete(id)
        return p
      }),
    isDialogOpen: (id: string) => searchParams.has(id),
  }
}
