import { AxiosError } from "axios"

import {
  LimitInstanceError,
  NoFreeInstanceError,
  NoInstanceRunning,
  SessionAlreadyOpenError,
} from "@/config/errors"
import { axiosApi, axiosCore2 } from "@/lib/axios"

export type InstanceResponse = {
  server: string
  port: number
  expire_at: string
}

export async function getUserInstance(
  token: string
): Promise<InstanceResponse | null> {
  try {
    const { data } = await axiosCore2.get(`/instances/user-instance`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return data as InstanceResponse
  } catch (err) {
    if (err instanceof AxiosError && err.response?.status === 404) return null
    throw err
  }
}

export async function assignUserInstance(
  token: string
): Promise<InstanceResponse> {
  try {
    const { data } = await axiosCore2.post("/instances/assign", undefined, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return data as InstanceResponse
  } catch (err) {
    if (err instanceof AxiosError && err.response?.status === 503)
      throw new NoFreeInstanceError()
    if (err instanceof AxiosError && err.response?.status === 403)
      throw new LimitInstanceError()
    throw err
  }
}

export async function openInstanceSession(username: string, password: string) {
  try {
    const { data } = await axiosApi.post("/session", {
      username,
      password,
    })

    // Session takes a bit more time to open
    await new Promise((r) => setTimeout(r, 1000))
  } catch (err) {
    if (err instanceof AxiosError && err.response?.status === 409)
      throw new SessionAlreadyOpenError()
    if (err instanceof AxiosError && err.message === "Network Error")
      throw new NoInstanceRunning()
    throw err
  }
}
