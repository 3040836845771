import {
  authenticateUser,
  decodeToken,
  registerUser,
  updateUser,
} from "@/api/user"
import { getCookie } from "@/utils/getCookie"
import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { enqueueSnackbar } from "notistack"

type RegisterParams = {
  email: string
  password: string
  firstname: string
  lastname: string
  company_name: string
  role: string
  apis: string[]
}

async function register({
  email,
  password,
  firstname,
  lastname,
  company_name,
  role,
  apis,
}: RegisterParams) {
  await registerUser({
    email,
    firstname,
    lastname,
    password,
  })

  // Workaround to add additional fields without modifying the back-end
  const { token } = await authenticateUser(email, password)

  const decoded = decodeToken(token)
  const { id, params } = decoded

  const visitorFromPathname = getCookie("visitorFromPathname")
  const visitorFromGoogleAds = getCookie("visitorFromGoogleAds")

  await updateUser(
    id,
    {
      ...params,
      role,
      company_name,
      apis: apis?.join(","),
      visitorFromPathname,
      visitorFromGoogleAds,
    },
    token
  )
}

export function useSignUp() {
  return useMutation<
    void,
    AxiosError<{ message?: string; title?: string }>,
    RegisterParams,
    unknown
  >((params) => register(params), {
    onError: (error) => {
      console.log(error)
      enqueueSnackbar(
        error.response?.data.message ||
          error.response?.data.title ||
          "Unexpected error, try again",
        {
          variant: "error",
        }
      )
    },
  })
}
