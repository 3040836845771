import { Box, styled } from "@mui/material"

export const DotStopped = styled(Box)(({ theme }) => ({
  "&": {
    borderRadius: 8,
    height: 10,
    width: 10,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}))

export const DotDisconnected = styled(Box)(({ theme }) => ({
  "&": {
    borderRadius: 8,
    height: 10,
    width: 10,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}))

export const DotConnecting = styled(Box)(({ theme }) => ({
  "&": {
    borderRadius: 8,
    height: 10,
    width: 10,
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}))

export const DotRunning = styled(Box)(({ theme }) => ({
  "&": {
    position: "relative",
    borderRadius: 8,
    height: 10,
    width: 10,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))
