export class NoFreeInstanceError extends Error {
  constructor() {
    super(
      "We regret to inform you of a temporary delay in activating your account due to a shortage of server instances. Our team is actively working on deploying new servers, and we will notify you by email as soon as your access is ready. We apologize for any inconvenience and appreciate your patience."
    )
  }
}

export class LimitInstanceError extends Error {
  constructor() {
    super(
      "It seems you're already linked to an existing account. Please log in with your previous credentials. For assistance, contact support at start@unipile.com"
    )
  }
}

export class SessionAlreadyOpenError extends Error {
  constructor() {
    super(
      "An error occured during the setup of your account. For assistance, contact support at start@unipile.com"
    )
  }
}

export class NoInstanceRunning extends Error {
  constructor() {
    super("No instance running")
  }
}

export class NoInstanceAllocated extends Error {
  title = "No API Instance allocated"
  constructor() {
    super("Please login again to fix this issue.")
  }
}
