import { getUser, updateUser } from "@/api/user"
import {
  Alert,
  Button,
  Card,
  Divider,
  Link,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { BookIcon, ExternalLink } from "lucide-react"
import { useConfirm } from "material-ui-confirm"
import { enqueueSnackbar } from "notistack"
import { useForm } from "react-hook-form"

import { useAuth } from "@/hooks/useAuth"

export default function SettingsPage() {
  const auth = useAuth()

  if (!auth) return null

  const { isLoading, error, data, isError } = useQuery({
    queryKey: ["settings"],
    queryFn: () => getUser(auth.id, auth.token),
  })

  return (
    <Stack spacing={8}>
      <Stack flex={1}>
        <Typography variant="h3">Settings</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Configure your application.
        </Typography>
      </Stack>
      {!isLoading && data && (
        <>
          <GoogleAuthSettings
            defaultValues={{
              google_client_id: data.params?.google_client_id || "",
              google_client_secret: data.params?.google_client_secret || "",
            }}
            token={auth.token}
            user_id={auth.id}
            params={data.params || {}}
          />
          <MicrosoftAuthSettings
            defaultValues={{
              microsoft_client_id: data.params?.microsoft_client_id || "",
              microsoft_client_secret:
                data.params?.microsoft_client_secret || "",
            }}
            token={auth.token}
            user_id={auth.id}
            params={data.params || {}}
          />
        </>
      )}
      {isLoading && <Skeleton width="100%" height={50} />}
    </Stack>
  )
}

type GoogleAuthSettingsForm = {
  google_client_id: string
  google_client_secret: string
}

function GoogleAuthSettings({
  defaultValues,
  user_id,
  token,
  params,
}: {
  defaultValues: { google_client_id: string; google_client_secret: string }
  token: string
  user_id: string
  params: Record<string, string | number | boolean>
}) {
  const confirm = useConfirm()
  const queryClient = useQueryClient()
  const { register, formState, handleSubmit, reset } =
    useForm<GoogleAuthSettingsForm>({
      defaultValues,
    })

  const { mutate } = useMutation({
    mutationFn: async (data: GoogleAuthSettingsForm) => {
      await updateUser(user_id, { ...params, ...data }, token)
      return data
    },
    onSuccess: (data: GoogleAuthSettingsForm) => {
      queryClient.invalidateQueries(["settings"])
      enqueueSnackbar("Settings saved", { variant: "default" })
      reset(data)
    },
    onError: () => {
      enqueueSnackbar("Unexpected error", {
        variant: "error",
        title: "Can't save settings",
      })
    },
  })

  const onSubmit = async (data: GoogleAuthSettingsForm) => {
    confirm({
      title: "Note about this setting change",
      content: (
        <Alert severity="info" variant="outlined">
          You are about to perform an action that will disconnect all currently
          connected <strong>Gmail accounts</strong> within the next hour. You
          will need to manually reconnect each account. Please confirm that you
          understand and wish to proceed.
        </Alert>
      ),

      confirmationText: "Save settings",
    }).then(() => {
      mutate(data)
    })
  }

  return (
    <Card>
      <form id="google-oauth-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack p={6}>
          <Typography variant="h6">Google Auth Settings</Typography>
          <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 6 }}>
            Use your own Google Client Key for OAuth when connecting Gmail and
            Google Calendar accounts.
          </Typography>
          <Stack direction="column" spacing={2} maxWidth={400}>
            <div>
              <Typography variant="body2">Client ID</Typography>
              <TextField
                type="text"
                name="google_client_id"
                id="google_client_id"
                fullWidth
                inputRef={register({ required: false })}
              />
            </div>
            <div>
              <Typography variant="body2">Client Secret</Typography>
              <TextField
                type="text"
                name="google_client_secret"
                id="google_client_secret"
                fullWidth
                inputRef={register({ required: false })}
              />
            </div>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          p={2}
          pl={6}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body2">
            <Link
              href="https://developer.unipile.com/docs/google-oauth"
              target="_blank"
            >
              Read the documentation
              <ExternalLink
                size={14}
                style={{ marginLeft: 2, marginBottom: -2, marginRight: 2 }}
              />
            </Link>{" "}
            to learn how to setup.
          </Typography>
          <Button
            variant="outlined"
            disabled={!formState.isDirty}
            type="submit"
          >
            Save changes
          </Button>
        </Stack>
      </form>
    </Card>
  )
}

type MicrosoftAuthSettingsForm = {
  microsoft_client_id: string
  microsoft_client_secret: string
}

function MicrosoftAuthSettings({
  defaultValues,
  user_id,
  token,
  params,
}: {
  defaultValues: {
    microsoft_client_id: string
    microsoft_client_secret: string
  }
  token: string
  user_id: string
  params: Record<string, string | number | boolean>
}) {
  const confirm = useConfirm()
  const queryClient = useQueryClient()
  const { register, formState, handleSubmit, reset } =
    useForm<MicrosoftAuthSettingsForm>({
      defaultValues,
    })

  const { mutate } = useMutation({
    mutationFn: async (data: MicrosoftAuthSettingsForm) => {
      await updateUser(user_id, { ...params, ...data }, token)
      return data
    },
    onSuccess: (data: MicrosoftAuthSettingsForm) => {
      queryClient.invalidateQueries(["settings"])
      enqueueSnackbar("Settings saved", { variant: "default" })
      reset(data)
    },
    onError: () => {
      enqueueSnackbar("Unexpected error", {
        variant: "error",
        title: "Can't save settings",
      })
    },
  })

  const onSubmit = async (data: MicrosoftAuthSettingsForm) => {
    confirm({
      title: "Note about this setting change",
      content: (
        <Alert severity="info" variant="outlined">
          You are about to perform an action that will disconnect all currently
          connected <strong>Microsoft Outlook accounts</strong> within the next
          hour. You will need to manually reconnect each account. Please confirm
          that you understand and wish to proceed.
        </Alert>
      ),

      confirmationText: "Save settings",
    }).then(() => {
      mutate(data)
    })
  }

  return (
    <Card>
      <form id="microsoft-oauth-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack p={6}>
          <Typography variant="h6">
            Microsoft / Outlook Auth Settings
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 6 }}>
            Use your own Microsoft Client Key for OAuth when connecting Outlook
            accounts.
          </Typography>
          <Stack direction="column" spacing={2} maxWidth={400}>
            <div>
              <Typography variant="body2">Application (client) ID</Typography>
              <TextField
                type="text"
                name="microsoft_client_id"
                id="microsoft_client_id"
                fullWidth
                inputRef={register({ required: false })}
              />
            </div>
            <div>
              <Typography variant="body2">Client secret (value)</Typography>
              <TextField
                type="text"
                name="microsoft_client_secret"
                id="microsoft_client_secret"
                fullWidth
                inputRef={register({ required: false })}
              />
            </div>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          p={2}
          pl={6}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body2">
            <Link
              href="https://developer.unipile.com/docs/microsoft-oauth"
              target="_blank"
            >
              Read the documentation
              <ExternalLink
                size={14}
                style={{ marginLeft: 2, marginBottom: -2, marginRight: 2 }}
              />
            </Link>{" "}
            to learn how to setup.
          </Typography>
          <Button
            variant="outlined"
            disabled={!formState.isDirty}
            type="submit"
          >
            Save changes
          </Button>
        </Stack>
      </form>
    </Card>
  )
}
