import { Account, getAccountList } from "@/api/account"
import { WebhookTriggerEvent, createWebhook } from "@/api/webhook"
import WebhookFormProvider from "@/context/WebhookFormProvider"
import { WebhookLabelMap } from "@/routes/WebhookPage"
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormControlLabelProps,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  styled,
  useRadioGroup,
} from "@mui/material"
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import {
  Link2,
  Mail,
  MailOpen,
  MessageCircle,
  MousePointerClick,
  Send,
  SmilePlus,
  Wifi,
} from "lucide-react"
import { enqueueSnackbar } from "notistack"
import { PropsWithChildren, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Wizard, useWizard } from "react-use-wizard"

import { useDialog } from "@/hooks/useDialog"
import { useWebhookForm } from "@/hooks/useWebhookForm"

import ErrorAlert from "./ErrorAlert"

export default function WebhookForm() {
  const { closeDialog, isDialogOpen, openDialog } = useDialog()

  return (
    <>
      <Button variant="contained" onClick={() => openDialog("new")}>
        Create a webhook
      </Button>
      <Dialog open={isDialogOpen("new")} fullWidth maxWidth="sm">
        <WebhookFormProvider>
          <Wizard>
            <StepTrigger />
            <StepEvents />
            <StepAccounts />
            <StepRequest onSuccess={() => closeDialog("new")} />
          </Wizard>
        </WebhookFormProvider>
      </Dialog>
    </>
  )
}

function AccountItem({
  account,
  onClick,
  checked,
}: {
  account: Account
  onClick: () => void
  checked: boolean
}) {
  return (
    <ListItemButton
      onClick={onClick}
      selected={checked}
      sx={{ borderRadius: 0 }}
      title={
        account.connection_params.im?.phone_number ||
        account.connection_params.im?.id ||
        account.connection_params.mail?.imap_user
      }
    >
      <Checkbox size="small" checked={checked} disableRipple />
      <ListItemAvatar>
        <Avatar
          variant="rounded"
          sx={{ width: 20, height: 20 }}
          src={`/${account.type}.svg`}
        ></Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={account.name}
        primaryTypographyProps={{ variant: "body2" }}
      ></ListItemText>
    </ListItemButton>
  )
}

type RequestFormData = {
  url: string
}

function StepRequest({ onSuccess }: { onSuccess: () => void }) {
  const { previousStep } = useWizard()
  const { updateData, data } = useWebhookForm()
  const { register, handleSubmit } = useForm<RequestFormData>({
    defaultValues: {
      url: data.url || "",
    },
  })

  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: createWebhook,
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["webhooks"] })
      }, 300)
      enqueueSnackbar("Webhook successfully created")
      onSuccess()
    },
    onError: () => {
      enqueueSnackbar("Unexpected error", {
        variant: "error",
        title: "Can't create access token",
      })
    },
  })

  const onSubmit = (form_data: RequestFormData) => {
    updateData(form_data)

    if (!data.source || !data.name) {
      enqueueSnackbar("Missing data", {
        variant: "error",
        title: "Can't create access token",
      })
      return
    }

    mutate({
      ...(data.selection === "custom" && { account_ids: data.accounts }),
      ...(data.events && { events: data.events }),
      enabled: true,
      format: "json",
      name: data.name,
      source: data.source,
      request_url: form_data.url,
      headers: [{
        "key": "Content-Type",
        "value": "application/json"
      }]
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Box>
          <Stack spacing={1} mb={8}>
            <Typography variant="h3" textAlign="center">
              Create a webhook
            </Typography>
            <Typography variant="subtitle1" textAlign="center">
              Configure the callback request.
            </Typography>
          </Stack>
          <Typography variant="body2">Callback URL</Typography>
          <TextField
            type="url"
            name="url"
            inputRef={register({ required: true })}
            placeholder="https://domain.com/callback"
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" sx={{ mr: "auto" }} onClick={previousStep}>
          Go back
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={isLoading || isSuccess}
        >
          {isLoading || isSuccess ? "Creating.." : "Create webook"}
        </Button>
      </DialogActions>
    </form>
  )
}

type AccountFormData = {
  selection: "all" | "custom"
  accounts: string[]
}

function StepAccounts() {
  const { nextStep, previousStep, goToStep } = useWizard()
  const { data, updateData } = useWebhookForm()
  const { isError, error } = useInfiniteQuery({
    queryKey: ["accounts"],
    queryFn: getAccountList,
    getNextPageParam: (lastPage) => lastPage.cursor,
  })

  const { register, handleSubmit, control, watch, setValue } =
    useForm<AccountFormData>({
      defaultValues: {
        accounts: data.accounts || [],
        selection: data.selection || "all",
      },
    })

  register("accounts")

  const onSubmit = (payload: AccountFormData) => {
    if (!payload.accounts.length && payload.selection === "custom") {
      enqueueSnackbar("Select at least one account", {
        variant: "info",
      })
      return
    }

    updateData(payload)
    nextStep()
  }

  const handleGoBack = () => {
    if (data.source !== "account_status") previousStep()
    else goToStep(0)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Box>
          <Stack spacing={1} mb={8}>
            <Typography variant="h3" textAlign="center">
              Create a webhook
            </Typography>
            <Typography variant="subtitle1" textAlign="center">
              Select the accounts that should trigger this webhook.
            </Typography>
          </Stack>
          {isError ? (
            <ErrorAlert error={error} />
          ) : (
            <>
              <Controller
                control={control}
                name="selection"
                defaultValue="all"
                render={(field) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      control={<Radio size="small" />}
                      label={
                        <div>
                          <Typography variant="body2">All accounts</Typography>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                          >
                            Currently connected and connected in the future
                          </Typography>
                        </div>
                      }
                      value="all"
                      sx={{ mb: 4 }}
                    />
                    <Alert severity="info">
                      For specific accounts, please create your webhook with an
                      API call. <br />
                      <Link
                        target="_blank"
                        href="https://developer.unipile.com/reference/webhookscontroller_createwebhook"
                      >
                        Read documentation
                      </Link>
                    </Alert>
                    {/* <FormControlLabel
                      disabled={!hasAccounts}
                      control={<Radio size="small" />}
                      label={
                        <div>
                          <Typography variant="body2">
                            Only specific accounts
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color={
                              Boolean(hasAccounts)
                                ? "text.secondary"
                                : "warning.main"
                            }
                          >
                             {Boolean(hasAccounts)
                              ? "Make the selection below"
                              : "You must have connected accounts to choose this option"}
                          </Typography>
                        </div>
                      }
                      value="custom"
                    /> */}
                  </RadioGroup>
                )}
              />

              {/* {Boolean(hasAccounts) && (
                <Paper
                  sx={{
                    maxHeight: 240,
                    overflowY: "scroll",
                    ml: 7,
                    mt: 2,
                    opacity: selection === "custom" ? 1 : 0.5,
                    pointerEvents: selection === "custom" ? "all" : "none",
                  }}
                >
                  <Stack divider={<Divider />}>
                    {accountsData?.pages.map((page) =>
                      page.items.map((item) => (
                        <AccountItem
                          checked={accounts.includes(item.id)}
                          onClick={() => {
                            const checked = accounts.includes(item.id)

                            if (checked)
                              setValue(
                                "accounts",
                                accounts.filter((id) => item.id !== id)
                              )
                            else setValue("accounts", [...accounts, item.id])
                          }}
                          key={item.id}
                          account={item}
                        />
                      ))
                    )}
                    {hasNextPage && (
                      <Button onClick={() => fetchNextPage()}>
                        Load more...
                      </Button>
                    )}
                  </Stack>
                </Paper>
              )} */}
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" sx={{ mr: "auto" }} onClick={handleGoBack}>
          Go back
        </Button>
        <Button variant="contained" type="submit" disabled={isError}>
          Continue
        </Button>
      </DialogActions>
    </form>
  )
}

type TriggerFormData = {
  name: string
  source: "messaging" | "account_status" | "email" | "email_tracking" | undefined
}

function StepTrigger() {
  const { closeDialog } = useDialog()
  const { nextStep, goToStep, activeStep, stepCount } = useWizard()
  const { updateData, data } = useWebhookForm()

  const { register, handleSubmit, control } = useForm<TriggerFormData>({
    defaultValues: {
      name: data.name || "",
      source: data.source,
    },
  })

  const onSubmit = (payload: TriggerFormData) => {
    if (!payload.source) {
      enqueueSnackbar("Select a webhook trigger event.", {
        variant: "info",
      })
      return
    }

    updateData({
      name: payload.name,
      ...(payload.source !== data.source && {
        source: payload.source,
        events: [],
      }),
    })

    if (payload.source !== "account_status") nextStep()
    else goToStep(2)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Stack spacing={1} mb={8}>
          <Typography variant="h3" textAlign="center">
            Create a webhook
          </Typography>
          <Typography variant="subtitle1" textAlign="center">
            Choose a name and the data source for the webhook.
          </Typography>
        </Stack>
        <Typography variant="body2">Webhook name</Typography>
        <TextField
          placeholder="Name"
          name="name"
          inputRef={register({ required: true })}
          fullWidth
        />

        <Box mt={6}>
          <Controller
            control={control}
            name="source"
            defaultValue=""
            render={(field) => (
              <RadioGroup {...field}>
                <Grid container spacing={6}>
                  <Grid item xs={4}>
                    <PaperRadioButton value="account_status">
                      <Box color="warning.main">
                        <Wifi />
                      </Box>

                      <Typography variant="subtitle2" textAlign="center">
                        Account
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        Status update
                      </Typography>
                    </PaperRadioButton>
                  </Grid>
                  <Grid item xs={4}>
                    <PaperRadioButton value="messaging">
                      <Box color="info.main">
                        <MessageCircle />
                      </Box>

                      <Typography variant="subtitle2" textAlign="center">
                        Messaging
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        Multiple events
                      </Typography>
                    </PaperRadioButton>
                  </Grid>
                  <Grid item xs={4}>
                    <PaperRadioButton value="email">
                      <Box color="success.main">
                        <Mail />
                      </Box>
                      <Typography variant="subtitle2" textAlign="center">
                        Mailing
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        Multiple events
                      </Typography>
                    </PaperRadioButton>
                  </Grid>
                  <Grid item xs={4}>
                    <PaperRadioButton value="email_tracking">
                      <Box color="secondary.main">
                        <Mail />
                      </Box>
                      <Typography variant="subtitle2" textAlign="center">
                        Mail Tracking
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        Multiple events
                      </Typography>
                    </PaperRadioButton>
                  </Grid>
                </Grid>
              </RadioGroup>
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ mr: "auto" }}
          onClick={() => closeDialog("new")}
        >
          Cancel
        </Button>
        <Button variant="contained" type="submit">
          Continue
        </Button>
      </DialogActions>
    </form>
  )
}

function StepEvents() {
  const { nextStep, previousStep } = useWizard()
  const { updateData, data } = useWebhookForm()

  const [pickedEvents, setPickedEvents] = useState<
    Partial<Record<WebhookTriggerEvent, boolean>>
  >(
    data.events?.reduce(
      (acc, current) => {
        acc[current] = true
        return acc
      },
      {} as Partial<Record<WebhookTriggerEvent, boolean>>
    ) ?? {}
  )

  const handleUpdate = () => {
    updateData({
      events: Object.entries(pickedEvents).reduce(
        (result, [key, isChecked]) => {
          if (isChecked) result.push(key as WebhookTriggerEvent)
          return result
        },
        [] as WebhookTriggerEvent[]
      ),
    })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (Object.values(pickedEvents).every((isChecked) => !isChecked)) {
      enqueueSnackbar("Select at least one webhook trigger event.", {
        variant: "info",
      })
      return
    }
    handleUpdate()
    nextStep()
  }

  const onChange = (event: WebhookTriggerEvent) => {
    setPickedEvents((prev) => ({
      ...prev,
      [event]: !prev[event],
    }))
  }

  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        <Stack spacing={1} mb={8}>
          <Typography variant="h3" textAlign="center">
            Create a webhook
          </Typography>
          <Typography variant="subtitle1" textAlign="center">
            Pick the events on which the webhook should trigger.
          </Typography>
        </Stack>

        <List dense>
          {data.source === "email" && (
            <>
              <WebhookEventItem
                Icon={<Mail />}
                onChange={onChange}
                event="mail_received"
                checked={!!pickedEvents["mail_received"]}
              />
              <WebhookEventItem
                Icon={<Send />}
                onChange={onChange}
                event="mail_sent"
                checked={!!pickedEvents["mail_sent"]}
              />
            </>
          )}
          {data.source === "email_tracking" && (
            <>
              <WebhookEventItem
                Icon={<MailOpen />}
                onChange={onChange}
                event="mail_opened"
                checked={!!pickedEvents["mail_opened"]}
              />
              <WebhookEventItem
                Icon={<MousePointerClick />}
                onChange={onChange}
                event="mail_link_clicked"
                checked={!!pickedEvents["mail_link_clicked"]}
              />
            </>
          )}
          {data.source === "messaging" && (
            <>
              <WebhookEventItem
                Icon={<Mail />}
                onChange={onChange}
                event="message_received"
                checked={!!pickedEvents["message_received"]}
              />
              <WebhookEventItem
                Icon={<MailOpen />}
                onChange={onChange}
                event="message_read"
                checked={!!pickedEvents["message_read"]}
              />
              <WebhookEventItem
                Icon={<SmilePlus />}
                onChange={onChange}
                event="message_reaction"
                checked={!!pickedEvents["message_reaction"]}
              />
            </>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ mr: "auto" }}
          onClick={() => {
            handleUpdate()
            previousStep()
          }}
        >
          Go back
        </Button>
        <Button variant="contained" type="submit">
          Continue
        </Button>
      </DialogActions>
    </form>
  )
}

interface WebhookEventItemProps {
  onChange: (event: WebhookTriggerEvent) => void
  checked: boolean
  event: WebhookTriggerEvent
  Icon: JSX.Element
}

const WebhookEventItem: React.FC<WebhookEventItemProps> = ({
  onChange,
  checked,
  event,
  Icon,
}) => {
  return (
    <ListItem
      disablePadding
      secondaryAction={
        <Checkbox
          edge="end"
          onChange={() => onChange(event)}
          checked={checked}
        />
      }
    >
      <ListItemButton onClick={() => onChange(event)}>
        <ListItemAvatar sx={{ mr: 2 }}>{Icon}</ListItemAvatar>
        <ListItemText primary={WebhookLabelMap[event]} />
      </ListItemButton>
    </ListItem>
  )
}

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  "&.MuiFormControlLabel-root": {
    border: 1,
    borderColor: checked ? theme.palette.primary.main : theme.palette.divider,
    borderStyle: "solid",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(6),
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  "& .MuiFormControlLabel-label": {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
}))

function PaperRadioButton({
  children,
  value,
}: PropsWithChildren<{ value: string }>) {
  const radioGroup = useRadioGroup()

  let checked = false

  if (radioGroup) {
    checked = radioGroup.value === value
  }

  return (
    <StyledFormControlLabel
      sx={{ width: "100%", margin: 0 }}
      labelPlacement="top"
      checked={checked}
      label={children}
      value={value}
      control={<Radio disableRipple />}
    />
  )
}
