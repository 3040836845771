import { Alert, AlertTitle } from "@mui/material"

export default function ErrorAlert({ error }: { error: unknown }) {
  const message = error instanceof Error ? error.message : ""
  const title =
    error instanceof Error && "title" in error
      ? error.title
      : "Unexpected error"
  return (
    <Alert variant="filled" severity="error" sx={{ mt: 8 }}>
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  )
}
