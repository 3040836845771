import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from "react"
import { getUserInstance, InstanceResponse } from "@/api/instance"
import { getUser } from "@/api/user"
import { isDev } from "@/utils/isDev"

import { NoInstanceAllocated } from "@/config/errors"
import {
  axiosApi,
  axiosCore,
  resetInstanceBaseUrl,
  setInstanceBaseUrl,
} from "@/lib/axios"
import { loadCrisp, unloadCrisp } from "@/lib/crisp"
import { clearSentryUser, setSentryUser } from "@/lib/sentry"
import { Session } from "@/hooks/useSignIn"

export type User = Session & { instance: InstanceResponse }
export type AuthState = User | null

export const AuthStateContext = createContext<AuthState>(null)

export const AuthDispatchContext = createContext<
  Dispatch<SetStateAction<AuthState>>
>(() => {})

const stored = JSON.parse(localStorage.getItem("auth") || "null")

function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [auth, setAuth] = useState<AuthState>(stored)

  /**
   * Persist session
   */
  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(auth))
  }, [auth])

  /**
   * Add context to sentry and crisp
   */
  useLayoutEffect(() => {
    if (auth) {
      setSentryUser(auth.email)
      loadCrisp(auth.email)
      setInstanceBaseUrl(
        !isDev,
        auth.instance.server,
        auth.instance.port,
        isDev
      )
    } else {
      clearSentryUser()
      unloadCrisp()
      resetInstanceBaseUrl()
    }
  }, [auth])

  /**
   * Update axios instance to handle authorization headers and refresh token
   * useLayoutEffet to make sure it's call before the first request is made
   */
  useLayoutEffect(() => {
    // Authenticate each request to the API
    const apiRequestIntercept = axiosApi.interceptors.request.use(
      (config) => {
        if (auth?.token && !config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.token}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // Automate token refresh on expiration
    const responseIntercept = axiosApi.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config
        if (
          error?.response?.status === 401 &&
          !prevRequest?.sent &&
          auth?.refresh_token
        ) {
          prevRequest.sent = true
          const newAccessToken = await refreshToken(auth.refresh_token)
          setAuth((prev) => {
            if (prev) return { ...prev, ...newAccessToken }
            else return null
          })
          prevRequest.headers[
            "Authorization"
          ] = `Bearer ${newAccessToken.token}`
          return axiosApi(prevRequest)
        }
        if (error?.response?.status === 503 && auth?.token) {
          const instance = await getUserInstance(auth?.token)
          if (!instance) {
            return Promise.reject(new NoInstanceAllocated())
          }
        }
        return Promise.reject(error)
      }
    )

    return () => {
      axiosApi.interceptors.request.eject(apiRequestIntercept)
      axiosApi.interceptors.response.eject(responseIntercept)
    }
  }, [auth?.token, auth?.refresh_token])

  return (
    <AuthStateContext.Provider value={auth}>
      <AuthDispatchContext.Provider value={setAuth}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

type RefreshTokenResponse = {
  refresh_token: string
  token: string
}

export async function refreshToken(refresh_token: string) {
  const response = await axiosCore.post("/api/token/refresh", {
    refresh_token,
  })

  return response.data as RefreshTokenResponse
}

export default AuthProvider
