import { axiosApi } from "@/lib/axios"

export async function getWebhookList({
  pageParam = undefined,
}: {
  pageParam?: string
}) {
  const { data } = await axiosApi.get(`/webhooks`, {
    params: { limit: 100, ...(pageParam && { cursor: pageParam }) },
  })

  return data as WebhookListResponse
}

export async function createWebhook(body: WebhookBody) {
  const { data } = await axiosApi.post("/webhooks", body)

  return data as WebhookCreatedResponse
}

export async function deleteWebhook(id: string) {
  const { data } = await axiosApi.delete(`/webhooks/${id}`)

  return
}

export type WebhookTriggerEvent =
  | "message_received"
  | "message_read"
  | "message_reaction"
  | "mail_received"
  | "mail_sent"
  | "mail_opened"
  | "mail_link_clicked"

export type Webhook = {
  account_ids: string[]
  enabled: boolean
  format: string
  id: string
  name: string
  request_url: string
  source: "messaging" | "email" | "email_tracking" | "account_status"
  events?: WebhookTriggerEvent[]
  data?: { name: string; key: string }[]
}

type WebhookListResponse = {
  cursor: string
  items: Webhook[]
  object: "WebhookList"
}

type WebhookCreatedResponse = {
  object: "WebhookCreated"
  webhook_id: string
}

interface WebhookBody {
  request_url: string
  name: string
  format: "json"
  account_ids?: string[]
  enabled: boolean
  headers?: {
    key: string
    value: string
  }[]
  source: "messaging" | "account_status" | "email"
  data?: {
    name: string
    key: string
  }[]
  events?: WebhookTriggerEvent[]
}
