import dayjs from "dayjs"

import { useAuth } from "./useAuth"

export function useSubscriptionStatus() {
  const auth = useAuth()

  const isFreeTrialActive =
    auth && !auth.offer_value && dayjs(auth.instance.expire_at).isAfter()
  const isSubscriptionActive = auth && Boolean(auth.offer_value)
  const canUseApi = isFreeTrialActive || isSubscriptionActive

  return {
    isFreeTrialActive,
    isSubscriptionActive,
    canUseApi,
    dsn: auth ? `${auth.instance.server}:${auth.instance.port}` : "",
    auth,
  }
}
