import { useState } from "react"
import { getAccountReconnectLink, restartAccount } from "@/api/account"
import { Button, CircularProgress } from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { LogIn, RotateCcw } from "lucide-react"
import { enqueueSnackbar } from "notistack"

import { useLocalStorage } from "@/hooks/useLocalStorage"

export function LoadMoreButton({
  hasNextPage,
  isFetchingNextPage,
  onClick,
}: {
  hasNextPage: boolean | undefined
  isFetchingNextPage: boolean
  onClick: () => void
}) {
  if (!hasNextPage) return null
  return (
    <Button
      variant="outlined"
      fullWidth
      disabled={isFetchingNextPage}
      sx={{ mt: 4 }}
      onClick={onClick}
    >
      {isFetchingNextPage ? "Loading more..." : "Load more"}
    </Button>
  )
}

export function ReconnectButton({ account_id }: { account_id: string }) {
  const { mutate } = useMutation({
    mutationFn: getAccountReconnectLink,
    onSuccess: (data) => {
      window.location.replace(data.url)
    },
    onError: (err: any) => {
      enqueueSnackbar(err?.response?.data?.detail, {
        variant: "error",
        title: "Error",
      })
    },
  })
  return (
    <Button
      color="warning"
      startIcon={<LogIn size={16} />}
      variant="outlined"
      onClick={() => {
        mutate(account_id)
      }}
    >
      Login again
    </Button>
  )
}

export function RestartButton({ account_id }: { account_id: string }) {
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationFn: restartAccount,
    onSuccess: (data) => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["accounts"] })
        enqueueSnackbar("Account restarted", { variant: "default" })
      }, 300)
    },
    onError: () => {
      setLoading(false)
      enqueueSnackbar("Unexpected error", {
        variant: "error",
        title: "Fail to restart account.",
      })
    },
  })

  const handleClick = async () => {
    setLoading(true)
    mutate(account_id)
  }
  return (
    <Button
      color="error"
      startIcon={<RotateCcw className={loading ? "spinning" : ""} size={16} />}
      disabled={loading}
      variant="outlined"
      onClick={handleClick}
    >
      {loading ? "Restarting..." : "Restart"}
    </Button>
  )
}
