import {
  assignUserInstance,
  getUserInstance,
  InstanceResponse,
  openInstanceSession,
} from "@/api/instance"
import { authenticateUser, decodeToken, JWT, SignInResponse } from "@/api/user"
import { isDev } from "@/utils/isDev"
import { Button } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import dayjs from "dayjs"
import { closeSnackbar, enqueueSnackbar } from "notistack"

import {
  LimitInstanceError,
  NoFreeInstanceError,
  NoInstanceRunning,
  SessionAlreadyOpenError,
} from "@/config/errors"
import { setInstanceBaseUrl } from "@/lib/axios"
import { useAuthDispatch } from "@/hooks/useAuth"

const devInstance = {
  server: "127.0.0.1",
  port: 3114,
  expire_at: dayjs().add(10, "d").toISOString(),
}

export type Session = SignInResponse & JWT

async function signIn(input: {
  email: string
  password: string
}): Promise<Session & { instance: InstanceResponse }> {
  const { refresh_token, token } = await authenticateUser(
    input.email,
    input.password
  )
  const decoded = decodeToken(token)
  const { email, firstname, id, lastname, subscription_id, offer_value } =
    decoded

  // Retrieve the assign instance if any
  let instance = isDev ? devInstance : await getUserInstance(token)

  try {
    if (!instance) {
      // Assign instance if not assigned yet
      instance = await assignUserInstance(token)

      // Set instance domain as baseURL for subsequent requests
      setInstanceBaseUrl(!isDev, instance.server, instance.port, isDev)

      // Start session on the instance
      await openInstanceSession(input.email, input.password)
    } else {
      setInstanceBaseUrl(!isDev, instance.server, instance.port, isDev)

      // Start session on the instance
      await openInstanceSession(input.email, input.password)
    }
  } catch (err) {
    if (
      !(
        err instanceof SessionAlreadyOpenError ||
        err instanceof NoInstanceRunning
      )
    )
      throw err
    // Here session is already open
  }

  if (!instance) throw new Error()

  return {
    refresh_token,
    token,
    email,
    firstname,
    id,
    lastname,
    instance,
    subscription_id,
    offer_value,
  }
}

export function useSignIn() {
  const setAuth = useAuthDispatch()

  return useMutation<
    Session & { instance: InstanceResponse },
    AxiosError<{ message: string }>,
    { email: string; password: string },
    unknown
  >((inputs) => signIn(inputs), {
    onSuccess: (data) => {
      setAuth(data)
    },
    onError: (error) => {
      const isImportantError =
        error instanceof LimitInstanceError ||
        error instanceof NoFreeInstanceError
      enqueueSnackbar(
        error.response?.data.message ||
          error.message ||
          "Unexpected error, try again",
        {
          variant: "error",
          persist: isImportantError,
          ...(isImportantError && {
            action: (key) => (
              <Button onClick={() => closeSnackbar(key)}>Ok</Button>
            ),
          }),
        }
      )
    },
  })
}
