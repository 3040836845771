import React from "react"
import AuthProvider from "@/context/AuthProvider"
import ConfirmProvider from "@/context/ConfirmProvider"
import QueryClientProvider from "@/context/QueryClientProvider"
import SnackbarProvider from "@/context/SnackbarProvider"
import ThemeProvider from "@/context/ThemeProvider"
// import TourProvider from "@/context/TourProvider"
import ReactDOM from "react-dom"

import Router from "./routes/Router"

import "@/lib/sentry"
import "@/lib/crisp"

import TagManager from "react-gtm-module"

import { GlobalErrorBoundary } from "./context/GlobalErrorBoundary"

console.log({ version: APP_VERSION })

TagManager.initialize({
  gtmId: "GTM-F9L8KFB",
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <GlobalErrorBoundary>
        <ConfirmProvider>
          <SnackbarProvider>
            <AuthProvider>
              <QueryClientProvider>
                {/* <TourProvider> */}
                <Router />
                {/* </TourProvider> */}
              </QueryClientProvider>
            </AuthProvider>
          </SnackbarProvider>
        </ConfirmProvider>
      </GlobalErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")!
)
