import { Base64 } from "./Base64"
import { WebCrypto } from "./WebCrypto"

export type Interface<T> = {
  [P in keyof T]: T[P]
}

/**
 * Differentiate between key material, generated, derived CryptoKey.
 *
 * @note Opaque type.
 */
declare const validDerivedCryptoKey: unique symbol
// export type DerivedCryptoKey = CryptoKey & { [validDerivedCryptoKey]: true };
export interface DerivedCryptoKey extends CryptoKey {
  [validDerivedCryptoKey]: true
}

/** @note Opaque type. */
declare const validGeneratedCryptoKey: unique symbol
export interface GeneratedCryptoKey extends CryptoKey {
  [validGeneratedCryptoKey]: true
}

/**
 * A GeneratedCryptoKey with usage ['wrapKey', 'unwrapKey'].
 *
 * @note Opaque type.
 */
declare const validWrappingKey: unique symbol
export interface WrappingCryptoKey extends GeneratedCryptoKey {
  [validWrappingKey]: true
}

/**
 * A GeneratedCryptoKey with usage ['wrapKey', 'unwrapKey'].
 *
 * @note Opaque type.
 */
declare const validEncryptionKey: unique symbol
export interface EncryptionCryptoKey extends GeneratedCryptoKey {
  [validEncryptionKey]: true
}

// export const wrappingUsages = ['wrapKey', 'unwrapKey'] as const;
// export const encryptionUsages = ['encrypt', 'decrypt'] as const;
// export const encryptionAndWrappingUsages = ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey'] as const;
// export type WrappingUsages = typeof wrappingUsages;
// export type EncryptionUsages = typeof encryptionUsages;
// export type EncryptionAndWrappingUsages = typeof encryptionAndWrappingUsages;
export type WrappingUsages = ["wrapKey", "unwrapKey"]
export type EncryptionUsages = ["encrypt", "decrypt"]
export type EncryptionAndWrappingUsages = [
  "encrypt",
  "decrypt",
  "wrapKey",
  "unwrapKey"
]
//   | ['wrapKey', 'unwrapKey', 'encrypt', 'decrypt'];

/** @note Opaque type. */
declare const validKeyMaterial: unique symbol
export interface KeyMaterial extends CryptoKey {
  [validKeyMaterial]: true
}

/**
 * @note SubtleCrypto.importKey() throws :
 *         - SyntaxError when keyUsages is empty but the unwrapped key is of type secret or private,
 *         - TypeError when given keyData is invalid for given format.
 *
 *       SubtleCrypto.exportKey() throws :
 *         - InvalidAccessError when given key is non-extractable,
 *         - NotSupported when given format is unknown,
 *         - TypeError when given format is unknown ???
 *
 * @note Opaque type.
 */
declare const validWrappedKey: unique symbol
export type WrappedKey = Base64 & { [validWrappedKey]: true }

/**
 * @note Opaque type.
 */
declare const validExportedKey: unique symbol
export type ExportedRawKey = Base64 & { [validExportedKey]: true }

/**
 *
 */
export const ACCESS_TOKEN_SEPARATOR = "."
type AccessTokenSeparator = typeof ACCESS_TOKEN_SEPARATOR

/**
 * @note Opaque type.
 */
declare const validAccessTokenPrefix: unique symbol
export type AccessTokenPrefix = Base64 & { [validAccessTokenPrefix]: true }

/**
 * @note Opaque type.
 */
declare const validAccessTokenSecret: unique symbol
export type AccessTokenSecret = Base64 & { [validAccessTokenSecret]: true }

/**
 *
 */
export type AccessTokenString =
  `${AccessTokenPrefix}${AccessTokenSeparator}${AccessTokenSecret}`

/**
 * @note Opaque type.
 */
declare const validHashedAccessTokenSecret: unique symbol
export type HashedAccessTokenSecret = Base64 & {
  [validHashedAccessTokenSecret]: true
}

/**
 *
 */
export type HashedAccessToken =
  `${AccessTokenPrefix}${AccessTokenSeparator}${HashedAccessTokenSecret}`

/**
 *
 */
// export interface CryptoCtor {
//   new (...args: ConstructorParameters<typeof WebCrypto>): WebCrypto;
// }

// // export type Crypto = typeof WebCrypto & CryptoCtor;
// type CryptoStatic = typeof WebCrypto;
// export interface Crypto extends CryptoStatic, CryptoCtor {}
export interface Crypto extends Interface<typeof WebCrypto> {}

/**
 *
 */
export class UnavailableCryptoApiError extends Error {
  constructor(readonly actor?: string) {
    super(
      `Underlying cryptography API is not available${
        actor ? " to use in " + actor : ""
      } !`
    )
    this.name = "UnavailableCryptoApiError"
  }
}
